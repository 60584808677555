import React, { FC } from 'react';
import 'numeral/locales/nl-nl';
import { OptionList } from '../../../store/configurator/types';
import { ImageOptionLine } from './';

interface OptionLineProps {
  optionList: OptionList;
  authorized?: boolean;
}

const OptionLine: FC<OptionLineProps> = ({ optionList, authorized }) => {
  const selectedOptions = optionList.options.filter((option) => option.selected);
  return (
    <>
      {selectedOptions.map(selectedOption => <ImageOptionLine
        key={`${optionList.optionListId}-${selectedOption.lineNr}`}
        optionList={optionList}
        selectedOption={selectedOption}
        authorized={authorized}
      />)}
    </>
  );
};

export default OptionLine;
