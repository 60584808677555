import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Option } from '../../../store/configurator/types';
import numeral from 'numeral';

interface TextOptionProps {
  onClick: () => void;
  option: Option;
}

const Wrapper = styled.div<{
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin: 0 6px 16px;
  height: 32px;
  border: 1px solid #eceff1;
  padding: 0 8px;
  color: #90a4ae;

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 700;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
      border: none;
    `}

  &:hover {
    cursor: pointer;
  }
`;

const Price = styled.span`
  font-size: 0.75rem;
  margin-left: 0.25rem;
`;

const TextOption: FC<TextOptionProps> = ({ option, onClick }) => {
  return (
    <Wrapper selected={!!option.selected} onClick={onClick}>
      {option.name}
      {option.price !== 0 && <Price>{`(+ ${numeral(option.price).format('$0,0.00')})`}</Price>}
    </Wrapper>
  );
};

export default TextOption;
