import React, { FC, useMemo, useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import { InputPreview, Label, Select } from './';
import { Box } from '../Layout';
import Button, { BoxButtonButton } from '../../atoms/Button';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { H2 } from '../../atoms/Heading';
import * as yup from 'yup';
import Error from './Error';
import InputGroup from './InputGroup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { placeOrderReset } from '../../../store/order/actions';
import { OrderState } from '../../../store/order/types';
import Textarea from './Textarea';
import Checkbox from './Checkbox';
import { GlobalsState } from '../../../store/globals/types';

export type FormTypes = 'ORDER' | 'QUOTATION';
interface UserPreviewFormProps {
  hasModuleOrders: boolean;
  onSubmit: (
    values: UserValues,
    helpers: FormikHelpers<UserValues>,
    type: FormTypes,
  ) => Promise<any>;
}

export interface UserValues {
  firstName: string;
  insertion: string;
  lastName: string;
  street: string;
  houseNumber?: number;
  houseNumberAddition: string;
  zipCode: string;
  place: string;
  number: string;
  email: string;
  sellerCode: string;
  shareDesign: boolean;
  shareDesignStore: boolean;
  comments?: string;
  country: number;
  expectedDeliveryDate?: Date;

  orderReference: string; // Orderreferentie/klantnaam max 20 letters
  orderNumberDealer?: string; // Ordernummer dealer: max 7 cijfers
  emailConsumer?: string; // Emailadres consument
  seatHeight?: string; // Zithoogte 3 cijfers in millimeters
  setback?: string; // Setback 3 cijfers in millimeters
}

const Header = styled.div`
  margin-bottom: 2rem;
`;

const OrderButton = styled(Button)`
  margin-top: 1rem;
`;

// Formik:
const initialValues: UserValues = {
  firstName: '',
  insertion: '',
  lastName: '',
  street: '',
  houseNumberAddition: '',
  zipCode: '',
  place: '',
  number: '',
  email: '',
  sellerCode: '',
  shareDesign: false,
  shareDesignStore: false,
  comments: undefined,
  country: 1,
  expectedDeliveryDate: undefined,

  orderReference: '',
  orderNumberDealer: '',
  emailConsumer: '',
  seatHeight: '',
  setback: ''
};



const UserPreviewForm: FC<UserPreviewFormProps> = ({ hasModuleOrders, onSubmit }) => {
  // Redux:
  const dispatch = useDispatch();
  const order = useSelector<RootState, OrderState>(({ order }) => order);
  const globals = useSelector<RootState, GlobalsState>(({ globals }) => globals);
  const [formType, setFormType] = useState<FormTypes>('ORDER');

  const { minExpectedDeliveryDate, minExpectedDeliveryDateM1, deliveryTimeWeeks } = useMemo(() => {
    const minExpectedDeliveryDate = new Date();
    const minExpectedDeliveryDateM1 = new Date();
    const deliveryTimeWeeks = globals.globals?.deliveryTimeWeeks ?? 0;
    minExpectedDeliveryDate.setDate(new Date().getDate() + (deliveryTimeWeeks * 7));
    minExpectedDeliveryDateM1.setDate(new Date().getDate() + (deliveryTimeWeeks * 7) - 1);
    return {
      minExpectedDeliveryDate, minExpectedDeliveryDateM1, deliveryTimeWeeks
    };
  }, [globals.globals]);

  const validationSchema = useMemo(() => {
    return hasModuleOrders
      ? yup.object().shape({
        firstName: yup.string().required('Voornaam is verplicht'),
        insertion: yup.string(),
        lastName: yup.string().required('Achternaam is verplicht'),
        street: yup.string().required('Straat is verplicht'),
        houseNumber: yup.number().required('Huisnummer is verplicht'),
        zipCode: yup.string().required('Postcode is verplicht'),
        place: yup.string().required('Woonplaats is verplicht'),
        number: yup.string().required('Telefoonnummer is verplicht'),
        email: yup
          .string()
          .required('E-mailadres is verplicht')
          .email('Ongeldig e-mailadres'),
        sellerCode: yup.string().required('Verkoperscode is verplicht'),
        comments: yup.string().notRequired().test('numlines', 'Max. 2 regels', val => { // max 2 lines
          if (val == null) { return true; }
          if (typeof (val) !== 'string') { return false; }
          const split = val.split('\n');
          if (split.length > 2) { return false; }
          return true;
        }).test('line_length', 'Max. regellengte overschreden', val => { // max line length of 38
          if (val == null) { return true; }
          if (typeof (val) !== 'string') { return false; }
          const split = val.split('\n');
          if (split.some(s => s.length > 38)) { return false; }
          return true;
        }),
        orderReference: yup.string().required('Orderreferentie/klantnaam is verplicht').max(20, 'Maximaal 20 karackters'),
        orderNumberDealer: yup.string().notRequired().matches(/^[0-9]+$/, "Alleen cijfers").max(7, 'Maximaal 7 cijfers'),
        emailConsumer: yup.string().notRequired().email('Ongeldig e-mailadres'),
        seatHeight: yup.string().notRequired().matches(/^[0-9]+$/, "Alleen cijfers").max(3, 'Maximaal 3 cijfers'),
        setback: yup.string().notRequired().matches(/^[0-9]+$/, "Alleen cijfers").max(3, 'Maximaal 3 cijfers'),
        country: yup.number().required('Land is verplicht'),
        expectedDeliveryDate: yup
          .date()
          .required('Leverdatum is verplicht')
          .nullable()
          .min(minExpectedDeliveryDateM1, () => deliveryTimeWeeks > 0
            ? deliveryTimeWeeks > 1 ? `Levertijd is minstens ${deliveryTimeWeeks} weken` : "Levertijd is minstens 1 week"
            : "Leverdatum moet in de toekomst liggen"),
      })
      : yup.object().shape({ // only: sellercode, comments and expectedDeliveryDate
        sellerCode: yup.string().required('Verkoperscode is verplicht'),
        comments: yup.string().notRequired().test('numlines', 'Max. 2 regels', val => { // max 2 lines
          if (val == null) { return true; }
          if (typeof (val) !== 'string') { return false; }
          const split = val.split('\n');
          if (split.length > 2) { return false; }
          return true;
        }).test('line_length', 'Max. regellengte overschreden', val => { // max line length of 38
          if (val == null) { return true; }
          if (typeof (val) !== 'string') { return false; }
          const split = val.split('\n');
          if (split.some(s => s.length > 38)) { return false; }
          return true;
        }),
        orderReference: yup.string().required('Orderreferentie/klantnaam is verplicht').max(20, 'Maximaal 20 karackters'),
        orderNumberDealer: yup.string().notRequired().matches(/^[0-9]+$/, "Alleen cijfers").max(7, 'Maximaal 7 cijfers'),
        emailConsumer: yup.string().notRequired().email('Ongeldig e-mailadres'),
        seatHeight: yup.string().notRequired().matches(/^[0-9]+$/, "Alleen cijfers").max(3, 'Maximaal 3 cijfers'),
        setback: yup.string().notRequired().matches(/^[0-9]+$/, "Alleen cijfers").max(3, 'Maximaal 3 cijfers'),
        expectedDeliveryDate: yup
          .date()
          .required('Leverdatum is verplicht')
          .nullable()
          .min(minExpectedDeliveryDateM1, () => deliveryTimeWeeks > 0
            ? deliveryTimeWeeks > 1 ? `Levertijd is minstens ${deliveryTimeWeeks} weken` : "Levertijd is minstens 1 week"
            : "Leverdatum moet in de toekomst liggen"),
      });
  }, [hasModuleOrders, minExpectedDeliveryDateM1, deliveryTimeWeeks]);

  const handlerSubmit = (
    values: UserValues,
    actions: FormikHelpers<UserValues>,
  ) => {
    return onSubmit(values, actions, formType);
  };

  const submitFormType = (formType: FormTypes, submitForm: () => void) => {
    setFormType(formType);
    submitForm();
  };

  if (order.orderId) {
    return (
      <div>
        <Box size="small">
          <H2>Bestelling is verstuurd</H2>
          <OrderButton
            primary
            onClick={() => {
              window.open(
                `https://mynotion.nl/Webshop/Order/Overview/${order.orderId}`,
              );
            }}
          >
            Bekijk bestelling
          </OrderButton>
        </Box>
        <BoxButtonButton onClick={() => dispatch(placeOrderReset())}>
          Nieuwe bestelling
        </BoxButtonButton>
      </div>
    );
  }

  if (order.isOrderFetching || globals.isFetching) {
    return (
      <div>
        <Box size="small">
          <H2>Aan het laden...</H2>
        </Box>
      </div>
    );
  }

  if (order.didOrderInvalidate) {
    return (
      <div>
        <Box size="small">
          <H2>Fout bij het binnenhalen v.d. orderdata</H2>
        </Box>
        <BoxButtonButton onClick={() => dispatch(placeOrderReset())}>
          Nieuwe bestelling
        </BoxButtonButton>
      </div>
    );
  }

  if (globals.didInvalidate) {
    return (
      <div>
        <Box size="small">
          <H2>Fout bij het binnenhalen v. globale data</H2>
        </Box>
        <BoxButtonButton onClick={() => dispatch(placeOrderReset())}>
          Nieuwe bestelling
        </BoxButtonButton>
      </div>
    );
  }

  return (
    <Formik
      onSubmit={handlerSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting, isValid, setFieldValue, handleSubmit }) => (
        <Form>
          <Box size="small">
            <Header>
              <H2>Bestelling</H2>
            </Header>
            {hasModuleOrders && <>
              <Row>
                <Col xs={12} sm={12}>
                  <InputGroup>
                    <Label htmlFor="firstName" required>
                      Voornaam
                    </Label>
                    <Field name="firstName" type="text" as={InputPreview} />
                    <ErrorMessage name="firstName" component={Error} />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={5}>
                  <InputGroup>
                    <Label htmlFor="insertion">Tussenvoegsel</Label>
                    <Field name="insertion" type="text" as={InputPreview} />
                    <ErrorMessage name="insertion" component={Error} />
                  </InputGroup>
                </Col>
                <Col xs={12} sm={7}>
                  <InputGroup>
                    <Label htmlFor="lastName" required>
                      Achternaam
                    </Label>
                    <Field name="lastName" type="text" as={InputPreview} />
                    <ErrorMessage name="lastName" component={Error} />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}>
                  <InputGroup>
                    <Label htmlFor="street" required>
                      Straatnaam
                    </Label>
                    <Field name="street" type="text" as={InputPreview} />
                    <ErrorMessage name="street" component={Error} />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <InputGroup>
                    <Label htmlFor="houseNumber" required>
                      Huisnummer
                    </Label>
                    <Field name="houseNumber" type="number" as={InputPreview} />
                    <ErrorMessage name="houseNumber" component={Error} />
                  </InputGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <InputGroup>
                    <Label htmlFor="houseNumberAddition">Toevoeging</Label>
                    <Field
                      name="houseNumberAddition"
                      type="text"
                      as={InputPreview}
                    />
                    <ErrorMessage name="houseNumberAddition" component={Error} />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <InputGroup>
                    <Label htmlFor="zipCode" required>
                      Postcode
                    </Label>
                    <Field name="zipCode" type="text" as={InputPreview} />
                    <ErrorMessage name="zipCode" component={Error} />
                  </InputGroup>
                </Col>
                <Col xs={12} md={6}>
                  <InputGroup>
                    <Label htmlFor="place" required>
                      Woonplaats
                    </Label>
                    <Field name="place" type="text" as={InputPreview} />
                    <ErrorMessage name="place" component={Error} />
                  </InputGroup>
                </Col>
              </Row>
              <InputGroup>
                <Label htmlFor="number" required>
                  Telefoonnummer
                </Label>
                <Field name="number" type="text" as={InputPreview} />
                <ErrorMessage name="number" component={Error} />
              </InputGroup>
              <InputGroup>
                <Label htmlFor="email" required>
                  E-mail
                </Label>
                <Field name="email" type="text" as={InputPreview} />
                <ErrorMessage name="email" component={Error} />
              </InputGroup>
            </>}
            <InputGroup>
              <Label htmlFor="expectedDeliveryDate" required>Leverdatum</Label>
              <Field
                name="expectedDeliveryDate"
                type="date"
                as={InputPreview}
                min={minExpectedDeliveryDate.toISOString().split('T')[0]}
              />
              <ErrorMessage name="expectedDeliveryDate" component={Error} />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="sellerCode" required>
                Verkoperscode
              </Label>
              <Field name="sellerCode" type="text" as={InputPreview} />
              <ErrorMessage name="sellerCode" component={Error} />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="comments">Orderreferentie</Label>
              <Field name="comments" as={Textarea} />
              <ErrorMessage name="comments" component={Error} />
            </InputGroup>


            <InputGroup>
              <Label htmlFor="orderReference" required>
                Orderreferentie/klantnaam
              </Label>
              <Field name="orderReference" type="text" as={InputPreview} />
              <ErrorMessage name="orderReference" component={Error} />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="orderNumberDealer">
                Ordernummer dealer
              </Label>
              <Field name="orderNumberDealer" type="text" as={InputPreview} />
              <ErrorMessage name="orderNumberDealer" component={Error} />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="emailConsumer">
                Emailadres consument
              </Label>
              <Field name="emailConsumer" type="text" as={InputPreview} />
              <ErrorMessage name="emailConsumer" component={Error} />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="seatHeight">
                Zithoogte
              </Label>
              <Field name="seatHeight" type="text" as={InputPreview} />
              <ErrorMessage name="seatHeight" component={Error} />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="setback">
                Setback
              </Label>
              <Field name="setback" type="text" as={InputPreview} />
              <ErrorMessage name="setback" component={Error} />
            </InputGroup>




            {hasModuleOrders && <>
              <InputGroup>
                <Label htmlFor="shareDesign">Ontwerp delen</Label>
                <Field name="shareDesign" type="checkbox" as={Checkbox} />
              </InputGroup>
              {!!values.shareDesign && !hasModuleOrders && (
                <InputGroup>
                  <Label htmlFor="email" required>
                    E-mail
                  </Label>
                  <Field name="email" type="text" as={InputPreview} />
                  <ErrorMessage name="email" component={Error} />
                </InputGroup>
              )}
              {!!values.shareDesign && (
                <InputGroup>
                  <Label htmlFor="shareDesignStore">
                    Ontwerp ook met winkel delen
                  </Label>
                  <Field name="shareDesignStore" type="checkbox" as={Checkbox} />
                </InputGroup>
              )}
              {/* @coolio: Zou jij hier een gestyled formik component omheen kunnen bouwen?*/}

              <InputGroup>
                <Label htmlFor="country" required>
                  Land
                </Label>
                <Field name="country" type="select" as={Select}>
                  <option value={1}>Nederland</option>
                  <option value={2}>België</option>
                </Field>
                <ErrorMessage name="country" component={Error} />
              </InputGroup>
            </>}
          </Box>
          <BoxButtonButton
            type="button"
            onClick={() => submitFormType('ORDER', handleSubmit)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Order doorboeken
          </BoxButtonButton>
          <BoxButtonButton
            type="button"
            onClick={() => submitFormType('QUOTATION', handleSubmit)}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Offerte
          </BoxButtonButton>
        </Form>
      )}
    </Formik>

  );

};

export default UserPreviewForm;
