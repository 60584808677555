import {
  GlobalsActionTypes,
  Globals,
  LOAD_GLOBALS_FAILURE,
  LOAD_GLOBALS_STARTED,
  LOAD_GLOBALS_SUCCESS,
} from './types';
import { Dispatch } from 'redux';
import api from '../../api';

export const loadGlobals = () => {
  return async (dispatch: Dispatch) => {
    dispatch(loadGlobalsStarted());

    try {
      const response = await api.get<Globals>(
        `${process.env.REACT_APP_API_ROOT}webshop/globals/get/${process.env.REACT_APP_SITE_ID}`,
      );
      dispatch(loadGlobalsSuccess(response.data));
    } catch (e) {
      dispatch(loadGlobalsFailure());
    }
  };
};

export const loadGlobalsStarted = (): GlobalsActionTypes => ({
  type: LOAD_GLOBALS_STARTED,
});

export const loadGlobalsSuccess = (
  globals: Globals,
): GlobalsActionTypes => ({
  type: LOAD_GLOBALS_SUCCESS,
  payload: {
    globals,
  },
});

export const loadGlobalsFailure = (): GlobalsActionTypes => ({
  type: LOAD_GLOBALS_FAILURE,
});
