import React, { FC } from 'react';
import styled from 'styled-components';
import IntroButton from '../atoms/IntroButton';

interface IntroFooterProps {
  productId: number;
}

const Footer = styled.div`
  /* background-color: #fff; */
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &:before {
    content: '';
    height: 1rem;
    width: 100%;
    background-color: #282f33;
    position: absolute;
    left: 0;
    bottom: 0;
  }
`;

const IntroFooter: FC<IntroFooterProps> = ({ productId }) => (
  <Footer>
    <IntroButton to={`/${productId}/configureren`}>Personaliseren</IntroButton>
  </Footer>
);

export default IntroFooter;
