import styled from 'styled-components';
import { Link } from 'react-router-dom';

const IntroButton = styled(Link)`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0.25rem 0.25rem 0 0;
  text-decoration: none;
  height: 4.5rem;
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15rem;
  z-index: 1;

  &:after {
      content: '›';
      padding-left: .5rem;
  }
`;

export default IntroButton;
