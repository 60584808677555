import styled from 'styled-components';
import { rgba } from 'polished';

const InputPreview = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  color: ${({ theme }) => theme.colors.gray[700]};
  font-size: 0.9375rem;
  line-height: 1rem;
  display: block;
  width: 100%;
  font-weight: 700;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: 0.5rem 1rem;
  transition: padding 0.4s;

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.gray[100]};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[100]};
  }

  &:disabled {
    border-color: transparent;
    border-bottom-color: ${({ theme }) => theme.colors.gray[50]};
    border-radius: 0;
    padding: 0 0 1rem 0;
    background-color: transparent;
  }
`;

export default InputPreview;
