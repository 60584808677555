import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../store';
import { Configuration, ImageValue } from '../../store/configurator/types';
import { getImageValueSrc } from '../../utils/cdn';
import {
  getOptionListImageValues,
  getOptionLists,
  getMaskColor,
} from '../../utils/optionList';
import { isEqual } from 'lodash';
import ImageLayer from '../molecules/ImageLayer';
import MaskLayer from '../molecules/MaskLayer';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { useParams } from 'react-router-dom';

interface BikeProps {
  productId?: number;
}

const Container = styled.div`
  width: 100%;
  position: relative;
  padding-top: 75%;
`;

const Images = styled.div<{ loading: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: ${({ loading }) => (loading ? 0.4 : 1)};
  transition: opacity 0.4s;
`;

const Loading = styled.div<{ loading: boolean }>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s;

  .mdi-icon {
    animation: spin 1s linear infinite;
    opacity: ${({ loading }) => (loading ? 1 : 0)};

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const renderImage = (image: HTMLImageElement, key: string | number) => (
  <ImageLayer key={key} uri={image.src} />
);

const renderMask = (
  image: HTMLImageElement,
  color: string,
  key: string | number,
) => <MaskLayer key={key} uri={image.src} color={color} />;

const getImageSrc = (imageValue: ImageValue) => {
  return getImageValueSrc(imageValue.fileName, imageValue.fileHash, 1200, 900);
};

const Bike: FC<BikeProps> = ({ productId }) => {
  // State.
  const [renewImages, setRenewImages] = useState(false);
  const [images, setImages] = useState<HTMLImageElement[]>([]);

  // Redux.
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  // const someConfigurationsLoading = configurations.some(c => c.isFetching);
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Render:
  // @ts-ignore
  const { loading, optionLists } = getOptionLists(configurations, id);
  const imageValues = getOptionListImageValues(optionLists);

  const loaded = () => {
    const a = imageValues.map((iv) => iv.fileHash);
    const b = images.map((image) => image.title);

    return isEqual(a, b);
  };

  const loadImage = async () => {
    if (!imageValues.length || renewImages || loaded()) {
      return;
    }

    setRenewImages(true);

    const promises = imageValues.map(
      (imageValue): Promise<HTMLImageElement> => {
        return new Promise((resolve) => {
          const existing = images.find((image) => {
            return image.title === imageValue.fileHash;
          });

          if (existing) {
            // Resolve existing image when present.
            return resolve(existing);
          }

          // Resolve image.
          const image = new Image();

          image.src = getImageSrc(imageValue);
          image.title = imageValue.fileHash;
          image.name = imageValue.name;

          image.onload = () => {
            resolve(image);
          };
        });
      },
    );

    // Skip first image.
    setImages(await Promise.all(promises));
    setRenewImages(false);
  };

  useEffect(() => {
    loadImage();
  }, [imageValues.map((iv) => iv.fileHash)]);

  const bikeLoading = (loading && !optionLists.length) || renewImages;

  return (
    <Container>
      <Images loading={bikeLoading}>
        {images.map((image, index) => {
          if (image.name.startsWith('image_mask_')) {
            const nr = parseInt(image.name.substr(-1));
            const color = getMaskColor(optionLists, nr - 1);
            return renderMask(image, color, index);
          }

          return renderImage(image, index);
        })}
      </Images>
      <Loading loading={bikeLoading}>
        <LoadingIcon />
      </Loading>
    </Container>
  );
};

export default Bike;
