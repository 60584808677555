import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 16px;
`;

export interface Country {
    id: number;
    name: string;
}

export interface CountryOptionListProps {
    countries: Country[];
    onSelect: (country: Country) => void;
}

const CountryOptionList: FC<CountryOptionListProps> = ({ countries, onSelect }) => {
    const handleChange = (event: any) => {
        const id = event.target.value;
        onSelect(countries.find(item => item.id.toString() === id)!);
    };

    return (
        <Wrapper>
            <select onChange={handleChange}>
                {countries.map(item => (
                    <option
                        key={item.id}
                        value={item.id}
                    >
                        {item.name}
                    </option>
                ))}
            </select>
        </Wrapper>
    );
};

export default CountryOptionList;
