import styled from 'styled-components';
import { rgba } from 'polished';
import React, { FC } from 'react';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

const Wrapper = styled.div`
  position: relative;
`;

const Options = styled.select`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  color: ${({ theme }) => theme.colors.gray[700]};
  padding: 0 4rem 0 1rem;
  font-size: 0.9375rem;
  display: block;
  width: 100%;
  margin-bottom: 1.5rem;
  appearance: none;
  background-color: #fff;

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.gray[100]};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  border-radius: ${({ theme }) =>
    `0 ${theme.borderRadius.small} ${theme.borderRadius.small} 0`};
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.gray[50]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`;

const Select: FC<React.ButtonHTMLAttributes<HTMLSelectElement>> = ({
  children,
  ...props
}) => (
  <Wrapper>
    <Options {...props} id={props.name}>
      {children}
    </Options>
    <Dropdown>
      <ChevronDownIcon />
    </Dropdown>
  </Wrapper>
);

export default Select;
