export interface Globals {
  deliveryTimeWeeks: number;
}

export const LOAD_GLOBALS_STARTED = 'LOAD_GLOBALS_STARTED';
export const LOAD_GLOBALS_SUCCESS = 'LOAD_GLOBALS_SUCCESS';
export const LOAD_GLOBALS_FAILURE = 'LOAD_GLOBALS_FAILURE';

export interface GlobalsState {
  globals: Globals | null;
  isFetching: boolean;
  didInvalidate: boolean;
}

export interface LoadGlobalsStarted {
  type: typeof LOAD_GLOBALS_STARTED;
}

export interface LoadGlobalsSuccess {
  type: typeof LOAD_GLOBALS_SUCCESS;
  payload: {
    globals: Globals;
  };
}

export interface LoadGlobalsFailure {
  type: typeof LOAD_GLOBALS_FAILURE;
}

export type GlobalsActionTypes =
  | LoadGlobalsStarted
  | LoadGlobalsSuccess
  | LoadGlobalsFailure;
