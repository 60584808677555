import React, { FC } from 'react';
import styled from 'styled-components';

interface IMaskLayerProps {
  uri: string;
  color: string;
}

const Mask = styled.div<{
  uri: string;
}>`
  mask-image: url("${({ uri }) => uri}");
  mask-repeat: no-repeat;
  mask-size: 100% auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.5s;
`;

const MaskLayer: FC<IMaskLayerProps> = ({ uri, color }) => {
  if (process.env.NODE_ENV === 'development') {
    return <Mask uri={uri} style={{ backgroundColor: color }} />;
  }

  return <Mask uri={uri} style={{ backgroundColor: color }} />;
};

export default MaskLayer;
