import styled from 'styled-components';

const H1 = styled.h1`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 3rem;
  line-height: 2.75rem;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
`;

export default H1;
