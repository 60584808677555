import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { RootState } from '../../../store';
import {
  Configuration,
  OptionList as IOptionList,
  OPTION_LIST_TYPE_COLOR,
  OPTION_LIST_TYPE_IMAGE,
  OPTION_LIST_TYPE_TEXT,
} from '../../../store/configurator/types';
import { setShowing } from '../../../store/overview/actions';
import {
  getDependentOptionLists,
  getOptionLists,
  getOptionListType,
} from '../../../utils/optionList';
import { BoxButtonButton } from '../../atoms/Button';
import Title from '../../atoms/Title';
import ColorOptionList from './ColorOptionList';
import ImageOptionList from './ImageOptionList';
import TextOptionList from './TextOptionList';
import Stock from '../../atoms/Stock';
import InformationVariantIcon from 'mdi-react/InformationVariantIcon';
import { Information } from '../../molecules/OptionList/ColorOptionList';
import ReactTooltip from 'react-tooltip';

export interface OptionListProps {
  optionList: IOptionList;
  last?: boolean;
  hideBottomButton?: boolean;
  child: boolean;
  authorized: boolean;
  productId?: number;
}

interface WrapperProps {
  last: boolean;
  child: boolean;
  active: boolean;
  noPadding: boolean;
  asParent?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 0 16px;
    `}

  ${({ last }) =>
    last &&
    css`
      height: 100vh;

      @media (max-width: 1040px) {
        height: auto;
        margin-bottom: ${({ theme }) => theme.footer.height.medium};
      }

      @media (max-width: 800px) {
        margin-bottom: ${({ theme }) => theme.footer.height.large};
      }
    `}
`;

const OptionListHeader = styled.div`
  display: flex;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;
`;

const renderOptionList = (
  optionLists: IOptionList[],
  optionList: IOptionList,
  child: boolean,
  authorized: boolean,
  productId?: number,
) => {
  switch (getOptionListType(optionLists, optionList.optionListId)) {
    case OPTION_LIST_TYPE_COLOR:
      return (
        <ColorOptionList
          authorized={authorized}
          key={optionList.optionListId}
          optionList={optionList}
          child={child}
          productId={productId}
        />
      );
    case OPTION_LIST_TYPE_IMAGE:
      return (
        <ImageOptionList
          authorized={authorized}
          key={optionList.optionListId}
          optionList={optionList}
          child={child}
          productId={productId}
        />
      );
    case OPTION_LIST_TYPE_TEXT:
      return (
        <TextOptionList
          authorized={authorized}
          key={optionList.optionListId}
          optionList={optionList}
          child={child}
          productId={productId}
        />
      );

    default:
      return null;
  }
};

const OptionList: FC<OptionListProps> = ({
  optionList,
  last,
  hideBottomButton,
  child,
  authorized,
  productId,
}) => {
  const history = useHistory();
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Redux:
  const dispatch = useDispatch();
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  // OptionList's methods:
  const { optionLists } = getOptionLists(configurations, id);

  const openOverview = () => {
    dispatch(setShowing(true));
  };

  const toOrder = () => {
    history.push(`/${id}/bestelling`);
  };

  // Render:
  const stock = optionList.options
    .find((option) => option.selected)
    ?.stock?.stockStores?.find((item) => item.dealerId === '4865-2');

  const info = optionList.properties?.find(
    (property) => property.name === 'info',
  )?.content;

  return (
    <Wrapper
      child={child}
      last={!!last}
      noPadding={false}
      active={!!optionList.active}
    >
      {!child && (
        <OptionListHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title title={optionList.name} />
            {info && (
              <>
                <Information info={info}>
                  <InformationVariantIcon />
                </Information>
                <ReactTooltip place="left" html />
              </>
            )}
          </div>
          {stock != null && authorized && <Stock>{stock.quantity}</Stock>}
        </OptionListHeader>
      )}
      {renderOptionList(optionLists, optionList, child, authorized, productId)}

      {/* Only render dependencies when type is not of color. */}
      {getOptionListType(optionLists, optionList.optionListId) !==
        OPTION_LIST_TYPE_COLOR &&
        getDependentOptionLists(optionLists, optionList.lineNr).map(
          (optionList) => (
            <OptionList
              authorized={authorized}
              key={optionList.optionListId}
              optionList={optionList}
              productId={productId}
              child
            />
          ),
        )}

      {last && (!hideBottomButton) && (
        <BoxButtonButton
          onClick={() => (authorized ? toOrder() : openOverview())}
        >
          {authorized ? 'Bestelling bekijken' : 'Resultaat bekijken'}
        </BoxButtonButton>
      )}
    </Wrapper>
  );
};

export default OptionList;
