/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { loadOrder } from '../../store/editOrder/actions';
import { EditOrderState } from '../../store/editOrder/types';
import { loadOptionLists } from '../../store/configurator/actions';
import { Configuration } from '../../store/configurator/types';
import { getOptionLists } from '../../utils/optionList';
import { loadProducts } from '../../store/product/actions';
import { ProductState } from '../../store/product/types';

interface LoadOrderProps {
  children: (productId: number) => ReactNode;
}

const LoadOrder: FC<LoadOrderProps> = ({ children }) => {
  // @ts-ignore
  const orderId = parseInt(useParams().id);
  const history = useHistory();

  // State.
  const [productId, setProjectId] = useState<number>();

  // Redux.
  const dispatch = useDispatch();
  const editOrder = useSelector<RootState, EditOrderState>(
    ({ editOrder }) => editOrder,
  );

  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const product = useSelector<RootState, ProductState>(
    ({ product }) => product,
  );

  useEffect(() => {
    handleLoadOrder();
  }, [orderId]);

  useEffect(() => {
    if (productId) {
      return;
    }

    if (editOrder.order) {
      const orderProduct = editOrder.order!.products[0];
      const order: { optionListId: number; optionId: number }[] =
        orderProduct.alterations.reduce(
          (a: { optionListId: number; optionId: number }[], c) => {
            const data = c.data.find(({ type }) => type === 'data')?.data;

            if (!data || typeof data === 'string') {
              return a;
            }

            if (data.dependencyOptionListId && data.dependencyOptionListOptionLineNr) {
              a.push({
                optionListId: data.dependencyOptionListId,
                optionId: data.dependencyOptionListOptionLineNr,
              });
            }

            a.push({
              optionListId: data.optionListId,
              optionId: data.optionId,
            });

            return a;
          },
          [],
        );

      const productId = orderProduct.productId;

      dispatch(loadOptionLists(productId, order));
      dispatch(loadProducts());

      setProjectId(productId);
    } else if (editOrder.didOrderInvalidate) {
      // The user is probaly not signed in.
      const path = history.location.pathname;
      history.replace(`/inloggen?redirect=${path}`);
    }
  }, [editOrder]);

  // Methods.
  const handleLoadOrder = useCallback(async () => {
    if (!orderId || productId) {
      return;
    }

    dispatch(loadOrder(orderId));
  }, []);

  const { loading } = getOptionLists(configurations, productId);

  if (
    editOrder.isOrderFetching ||
    editOrder.didOrderInvalidate ||
    productId === undefined ||
    product.isFetching ||
    product.didInvalidate ||
    loading
  ) {
    return <p>Laden...</p>;
  }

  return <>{children(productId)}</>;
};

export default LoadOrder;
