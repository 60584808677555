import styled, { css } from 'styled-components';
import { rgba } from 'polished';

export const BOX_SIZE_SMALL = 'small';
export const BOX_SIZE_MEDIUM = 'medium';

interface BoxProps {
  size?: typeof BOX_SIZE_SMALL | typeof BOX_SIZE_MEDIUM;
}

const Box = styled.div<BoxProps>`
  background-color: #fff;
  box-shadow: 0 -10px 20px ${({ theme }) => rgba(theme.colors.secondary, 0.1)};

  ${({ size = BOX_SIZE_MEDIUM }) => {
    switch (size) {
      case BOX_SIZE_MEDIUM:
        return css`
          padding: 3.5rem;
        `;
      case BOX_SIZE_SMALL:
        return css`
          padding: 1.5rem 2.5rem;
        `;
    }
  }}
`;

export default Box;
