import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { RootState } from '../../store';
import { toggleShowing } from '../../store/overview/actions';
import Overview from '../atoms/Overview';
import Price from '../atoms/Price';
import OverviewModal from '../organisms/OverviewModal';
import ShareDesign from '../organisms/ShareDesign';
import api from '../../api';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div<{
  show: boolean;
  up: boolean;
}>`
  width: 100%;
  height: ${({ theme }) => theme.footer.height.medium};
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  padding: 0 48px;
  justify-content: space-between;
  z-index: 3;
  transition: background-color 0.5s;

  ${({ show }) =>
    show &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
    `}

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    height: ${({ theme }) => theme.footer.height.large};
    padding: 0 16px;
    transition: bottom 0.4s;
    bottom: ${({ theme, up }) => (up ? 0 : `-${theme.footer.height.large}`)};
  }
`;

interface FooterProps {
  productId?: number;
}

const Footer: FC<FooterProps> = ({ productId }) => {
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // State.
  const [authorizing, setAuthorizing] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [up, setUp] = useState(false);
  let lastScrollTop = 0;

  // Redux.
  const dispatch = useDispatch();
  const showing = useSelector<RootState, boolean>(
    ({ overview }) => overview.showing,
  );

  useEffect(() => {
    isAuthorized();
  }, [id]);

  // Footer's methods.
  const isAuthorized = async () => {
    if (authorizing) {
      return;
    }

    setAuthorizing(true);

    api
      .get(`${process.env.REACT_APP_API_ROOT}account/authorized`)
      .then(() => setAuthorized(true))
      .catch(() => setAuthorized(false))
      .finally(() => setAuthorizing(false));
  };

  const toggleOverview = () => {
    dispatch(toggleShowing());
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setUp(scrollTop <= lastScrollTop);

    // For Mobile or negative scrolling
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  };

  const addScrollListener = () => {
    document.addEventListener('scroll', handleScroll);
  };

  const removeScrollListener = () => {
    document.removeEventListener('scroll', handleScroll);
  };

  // Footer's lifecycle:
  useEffect(() => {
    addScrollListener();

    return removeScrollListener;
  }, []);

  // Render:
  return (
    <>
      <OverviewModal authorized={authorized} />
      <Wrapper show={showing} up={showing || up}>
        <ShareDesign />
        <Overview show={showing} onClick={toggleOverview}>
          Bekijk je overzicht
        </Overview>
        <Price isOpen={showing} />
      </Wrapper>
    </>
  );
};

export default Footer;
