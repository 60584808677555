import {
  ProductActionTypes,
  Product,
  LOAD_PRODUCTS_FAILURE,
  LOAD_PRODUCTS_STARTED,
  LOAD_PRODUCTS_SUCCESS,
} from './types';
import { Dispatch } from 'redux';
import api from '../../api';

export const loadProducts = () => {
  return async (dispatch: Dispatch) => {
    dispatch(loadProductsStarted());

    try {
      const response = await api.get<Product[]>(
        `${process.env.REACT_APP_API_ROOT}webshop/product/all/${process.env.REACT_APP_SITE_ID}`,
      );
      dispatch(loadProductsSuccess(response.data));
    } catch (e) {
      dispatch(loadProductsFailure());
    }
  };
};

export const loadProductsStarted = (): ProductActionTypes => ({
  type: LOAD_PRODUCTS_STARTED,
});

export const loadProductsSuccess = (
  products: Product[],
): ProductActionTypes => ({
  type: LOAD_PRODUCTS_SUCCESS,
  payload: {
    products,
  },
});

export const loadProductsFailure = (): ProductActionTypes => ({
  type: LOAD_PRODUCTS_FAILURE,
});
