import {
  ProductState,
  ProductActionTypes,
  LOAD_PRODUCTS_STARTED,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCTS_FAILURE,
  LoadProductsSucces,
} from './types';
import { cloneDeep } from 'lodash';

// Hardcoded product order.
const order = [
  1671907, 1345481, 1639878, 1397921, 1316503, 1529830,
  1346782, 1347664, 1481529, 1543511, 1347994, 1347478, 1349239
];

const initialState: ProductState = {
  isFetching: false,
  didInvalidate: false,
  products: [],
};

const loadProductsStarted = (state: ProductState) => {
  const newState = cloneDeep(state);

  newState.isFetching = true;
  newState.didInvalidate = false;

  return {
    ...state,
    ...newState,
  };
};

const loadProductsSuccess = (
  state: ProductState,
  action: LoadProductsSucces,
) => {
  const newState = cloneDeep(state);

  newState.isFetching = false;
  newState.didInvalidate = false;
  newState.products = action.payload.products.sort(
    (a, b) => order.indexOf(a.id) - order.indexOf(b.id),
  );

  return {
    ...state,
    ...newState,
  };
};

const loadProductsFailure = (state: ProductState) => {
  const newState = cloneDeep(state);

  newState.isFetching = false;
  newState.didInvalidate = true;

  return {
    ...state,
    ...newState,
  };
};

export const productReducer = (
  state = initialState,
  action: ProductActionTypes,
): ProductState => {
  switch (action.type) {
    case LOAD_PRODUCTS_STARTED:
      return loadProductsStarted(state);
    case LOAD_PRODUCTS_SUCCESS:
      return loadProductsSuccess(state, action);
    case LOAD_PRODUCTS_FAILURE:
      return loadProductsFailure(state);
    default:
      return state;
  }
};
