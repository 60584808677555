export interface OrderState {
  exchangeBike: ExchangeBike;
  exchangeBikeActive: boolean;
  orderId?: number;
  isOrderFetching: boolean;
  didOrderInvalidate: boolean;
  totalPriceDiscount?: number;
}

export interface ExchangeBike {
  category: string;
  brand: string;
  type: string;
  color: string;
  frame: string;
  frameHeight: number;
  frameNumber: string;
  modelYear: number;
  price: number;
  description: string;
  image?: Blob;
  uploadedFile?: CdnFileResult;
}

export const SET_EXCHANGE_BIKE = 'SET_EXCHANGE_BIKE';

export interface SetExchangeBike {
  type: typeof SET_EXCHANGE_BIKE;
  payload: {
    exchangeBike: ExchangeBike;
  };
}

export const PLACE_ORDER_STARTED = 'PLACE_ORDER_STARTED';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const PLACE_ORDER_RESET = 'PLACE_ORDER_RESET';

export interface PlaceOrderStarted {
  type: typeof PLACE_ORDER_STARTED;
}

export interface PlaceOrderSucces {
  type: typeof PLACE_ORDER_SUCCESS;
  payload: {
    orderId: number;
  };
}

export interface PlaceOrderFailure {
  type: typeof PLACE_ORDER_FAILURE;
}

export interface PlaceOrderReset {
  type: typeof PLACE_ORDER_RESET;
}

export interface CartOrderPropertyDataConfiguration {
  optionListId: number;
  optionId: number;
  name: string;
  csBarcode: string;
}

export interface CartOrderPropertyConfiguration {
  id: number;
  name: string;
  type: string;
  displayValue: string;
  value: number;
  price: number;
  data: CartOrderPropertyDataConfiguration;
}

export interface CartOrderProduct {
  id: number;
  quantity: number;
  propertyConfiguration: CartOrderPropertyConfiguration[];
}

export interface CartOrder {
  firstName: string;
  lastName: string;
  zipcode: string;
  housenumberAndAddition: string;
  street: string;
  city: string;
  phoneNumber: string;
  email: string;
  newsletter: boolean;
  countryId: number;
  conditions: boolean;
  products: CartOrderProduct[];
  paymentMethodId: number;
  remark?: string;
  totalPriceDiscount?: number;
  deliveryDate?: string | Date;
  properties: { [key: string]: string };
}

export interface OrderOptions {
  baseUrl?: string;
  ip?: string;
  initialStateId?: number;
  hasAddress?: boolean;
  hasPayment?: boolean;
  hasProducts?: boolean;
  existingUserOnly?: boolean;
  checkProductWeight?: boolean;
  storeCurrentUserAsOrderBackofficeUser?: boolean;
}

export type OrderProductAlterationDataData = string | {
  optionId: number;
  optionListId: number;
}

export interface OrderProductAlterationData {
  type: string;
  data: OrderProductAlterationDataData;
}

export interface OrderProductAlteration {
  content: string;
  price: number;
  data: OrderProductAlterationData[];
}

export interface OrderProduct {
  productId: number;
  alterations: OrderProductAlteration[];
}

export interface Order {
  id: number;
  productTotalPrice: number;
  products: OrderProduct[];
}

export const SET_DISCOUNT_CODE = 'SET_DISCOUNT_CODE';
export const RESET_DISCOUNT_CODE = 'RESET_DISCOUNT_CODE';

export interface SetDiscountCode {
  type: typeof SET_DISCOUNT_CODE;
  payload: {
    discount: number;
  };
}

export interface ResetDiscountCode {
  type: typeof RESET_DISCOUNT_CODE;
}

export type OrderActionTypes =
  | SetExchangeBike
  | PlaceOrderStarted
  | PlaceOrderSucces
  | PlaceOrderReset
  | PlaceOrderFailure
  | SetDiscountCode
  | ResetDiscountCode

export interface CdnFileResult {
  hash: string;
  fileName: string;
}
