export interface Product {
  id: number;
  name: string;
  price: number;
  images: Image[];
}

export interface Image {
  title: string;
  fileName: string;
  fileHash: string;
}

export interface ProductState {
  products: Product[];
  isFetching: boolean;
  didInvalidate: boolean;
}

export const LOAD_PRODUCTS_STARTED = 'LOAD_OPTION_LISTS_STARTED';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_OPTION_LISTS_SUCCESS';
export const LOAD_PRODUCTS_FAILURE = 'LOAD_OPTION_LISTS_FAILURE';

export interface LoadProductsStarted {
  type: typeof LOAD_PRODUCTS_STARTED;
}

export interface LoadProductsSucces {
  type: typeof LOAD_PRODUCTS_SUCCESS;
  payload: {
    products: Product[];
  };
}

export interface LoadProductsFailure {
  type: typeof LOAD_PRODUCTS_FAILURE;
}

export type ProductActionTypes =
  | LoadProductsStarted
  | LoadProductsSucces
  | LoadProductsFailure;
