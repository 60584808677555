import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store';
import { Product } from '../../../store/product/types';
import { ProductOption } from '../../atoms/Option';
import Title from '../../atoms/Title';
import * as styles from './styles';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 0 16px;
`;

const OptionListWrapper = styled.div`
  ${styles.OptionList}
`;

const OptionListHeader = styled.div`
  display: flex;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;
`;

const ProductOptionList: FC = () => {
  // Router:
  const history = useHistory();

  // Redux:
  const products = useSelector<RootState, Product[]>(
    ({ product }) => product.products,
  );

  // ProductOptionList's methods:
  const onSelectProduct = (productId: number) => {
    history.push(`/${productId}/configureren`);
  };

  return (
    <Wrapper>
      <OptionListHeader>
        <Title title="Frame" active />
      </OptionListHeader>
      <OptionListWrapper child={false}>
        {products.map((product) => (
          <ProductOption
            key={product.id}
            product={product}
            onClick={() => onSelectProduct(product.id)}
          />
        ))}
      </OptionListWrapper>
    </Wrapper>
  );
};

export default ProductOptionList;
