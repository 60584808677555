import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import OptionLines from '../molecules/OptionLines';
import OptionLine, { FrameOptionLine } from '../molecules/OptionLine';
import Bike from './Bike';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import {
  Configuration,
  DEPENDENCY_TYPE_GROUP,
} from '../../store/configurator/types';
import Modal from 'react-modal';
import { getOptionLists, getVisibleOptionLists } from '../../utils/optionList';
import { setShowing } from '../../store/overview/actions';
import { useParams } from 'react-router-dom';

Modal.setAppElement('#root');

interface OverviewModalProps {
  className?: string;
  authorized?: boolean;
  productId?: number;
}

const Title = styled.h1`
  font-weight: 900;
  color: #d5d9db;
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 6rem;
  text-align: center;
  margin: 0;
  margin-bottom: -7rem;

  @media (max-width: 1300px) {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: -5rem;
  }
`;

const BikeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  flex: 1;
  margin-right: 32px;

  @media (max-width: 1100px) {
    display: none;
  }

  @media (max-width: 1300px) {
    flex: 0 0 24rem;
    width: 24rem;
  }
`;

const BikeSize = styled.div`
  margin-bottom: -3.4rem;

  @media (max-width: 1300px) {
    width: 24rem;
  }
`;

const OverviewModal: FC<OverviewModalProps> = ({
  className,
  authorized,
  productId,
  ...props
}) => {
  // Redux:
  const dispatch = useDispatch();
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const id = productId ?? parseInt((useParams() as { id: string }).id);

  const showing = useSelector<RootState, boolean>(
    ({ overview }) => overview.showing,
  );

  // OverviewModal's methods:
  const close = () => {
    dispatch(setShowing(false));
  };

  // Render:
  const { optionLists } = getOptionLists(configurations, id);
  const filteredOptionLists = getVisibleOptionLists(optionLists).filter(
    (optionList) => {
      return !optionList.dependencies.some((dependency) => {
        return dependency.type === DEPENDENCY_TYPE_GROUP;
      });
    },
  );

  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      closeTimeoutMS={400}
      isOpen={showing}
      onRequestClose={close}
      {...props}
    >
      <BikeWrapper>
        <BikeSize>
          <Title>
            Jouw <br />
            overzicht
          </Title>
          <Bike productId={productId} />
        </BikeSize>
      </BikeWrapper>
      <OptionLines>
        <tbody>
          <FrameOptionLine />
          {filteredOptionLists.map((optionList) => (
            <OptionLine
              authorized={authorized}
              key={optionList.optionListId}
              optionList={optionList}
            />
          ))}
        </tbody>
      </OptionLines>
    </Modal>
  );
};

const StyledOverviewModal = styled(OverviewModal)`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    bottom: ${({ theme }) => theme.footer.height.medium};
    
    @media (max-width: 800px) {
      bottom: ${({ theme }) => theme.footer.height.large};
    }
  }

  &__content {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    outline: none;
    height: ${({ theme }) => theme.overview.height};
    display: flex;
    justify-content: space-around;
    transition: bottom 0.4s;
    background: linear-gradient(90deg, #eceff1, #eceff1, #ffffff);
    transition: bottom 0.4s;
    padding: 2.5rem 48px 0;
    box-shadow: 10px -10px 20px 0px ${({ theme }) =>
      rgba(theme.colors.secondary, 0.1)};
    bottom: -${({ theme }) => theme.overview.height};

    @media (max-width: 800px) {
      padding: 2.5rem 16px 0;
    }

    &.ReactModal__Content--after-open {
      bottom: 0;
    }

    &.ReactModal__Content--before-close {
      bottom: -${({ theme }) => theme.overview.height};
    }
  }
}`;

export default StyledOverviewModal;
