/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ProductState } from '../../store/product/types';
import { loadProducts } from '../../store/product/actions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { loadOptionLists } from '../../store/configurator/actions';
import Configurator from '../templates/Configurator';
import queryString from 'query-string';

const Configure: FC = () => {
  // Router:
  const history = useHistory();
  // @ts-ignore
  const id = parseInt(useParams().id);
  const location = useLocation();

  // Redux:
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const product = useSelector<RootState, ProductState>(
    ({ product }) => product,
  );

  // Configure's lifecycle:
  useEffect(() => {
    dispatch(loadProducts());
  }, []);

  const initialize = async () => {
    if (!product.products.length) {
      return;
    }

    const parsed = queryString.parse(location.search);
    const configuration = Object.entries(parsed).reduce(
      (a: { [key: number]: number }, [key, value]) => {
        // @ts-ignore, string is assignable to isNaN.
        if (value && !isNaN(value)) {
          a[key] = parseInt(Array.isArray(value) ? value[0] : value);
        }

        return a;
      },
      {},
    );

    await dispatch(loadOptionLists(id, configuration));
    setLoading(false);

    if (!product.products.find((item) => item.id === id)) {
      history.push('/');
    }
  };

  useEffect(() => {
    initialize();
  }, [product, id]);

  if (loading) {
    return <p>Laden...</p>;
  }

  return <Configurator />;
};

export default Configure;
