import React, { FC } from 'react';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import { Tr, Title, Td, Price } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Product } from '../../../store/product/types';
import { useParams } from 'react-router-dom';

interface FrameOptionLineProps {
  productId?: number;
}

const FrameOptionLine: FC<FrameOptionLineProps> = ({ productId }) => {
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Redux:
  const product = useSelector<RootState, Product | undefined>(({ product }) => {
    return product.products.find((product) => {
      return product.id === id;
    });
  });

  return (
    <Tr>
      <Title>Frame</Title>
      <Td>{product?.name}</Td>
      <Price>
        {product &&
          product.price &&
          product.price > 0 &&
          numeral(product.price).format('$0,0.00')}
      </Price>
    </Tr>
  );
};

export default FrameOptionLine;
