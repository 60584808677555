import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RootState } from '../../store';
import {
  Configuration,
  DEPENDENCY_TYPE_GROUP,
} from '../../store/configurator/types';
import { getOptionLists, isVisibleOptionList } from '../../utils/optionList';
import Step from '../atoms/Step';

const Container = styled.div`
  width: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 1040px) {
    display: none;
  }
`;

const DURATION = 1000;

export interface NavigationStepsProps {
  hideProducts?: boolean;
  productId?: number;
}

const NavigationSteps: FC<NavigationStepsProps> = ({
  hideProducts,
  productId,
}) => {
  // Redux:
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  // const someConfigurationsLoading = configurations.some(c => c.isFetching);
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // OptionList's methods:
  const { optionLists } = getOptionLists(configurations, id);
  const filteredOptionLists = optionLists.filter((optionList) => {
    const visible = isVisibleOptionList(optionLists, optionList.lineNr);

    return (
      visible &&
      !optionList.dependencies.some((dependency) => {
        return dependency.type === DEPENDENCY_TYPE_GROUP;
      })
    );
  });

  return (
    <Container>
      {!hideProducts && (
        <Step
          key={'frame'}
          to={'frame'}
          smooth
          duration={DURATION}
          ignoreCancelEvents
          hashSpy
          spy
        >
          <span className="step">{1}</span>
          <span className="title">Frame</span>
        </Step>
      )}
      {filteredOptionLists.map((optionList, index) => {
        return (
          <Step
            key={optionList.optionListId}
            to={optionList.name.toLowerCase()}
            smooth
            duration={DURATION}
            // offset={scrollingUp ? 0 : -112}
            ignoreCancelEvents
            hashSpy
            spy
          >
            <span className="step">{index + 2}</span>
            <span className="title">{optionList.name}</span>
          </Step>
        );
      })}
    </Container>
  );
};

export default NavigationSteps;
