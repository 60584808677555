import styled from 'styled-components';

const Header = styled.div`
  padding: 3rem 48px 0;

  @media (max-width: 1040px) {
    padding: 1rem 16px 0;
  }
`;

export default Header;
