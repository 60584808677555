import React, { FC } from 'react';
import styled from 'styled-components';
import { Option } from '../../../store/configurator/types';
import { TextOption } from '../../atoms/Option';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../../store/configurator/actions';
import * as styles from './styles';
import { OptionListProps } from './OptionList';
import { useParams } from 'react-router-dom';

interface TextOptionListProps extends OptionListProps {}

const OptionListWrapper = styled.div`
  ${styles.OptionList}
  padding: ${({ theme }) => `${theme.space.medium} ${theme.space.small} 0`};
`;

const OptionListTitle = styled.h3`
  ${styles.OptionListTitle}
  padding: ${({ theme }) => `0 ${theme.space.small}`};
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TextOptionList: FC<TextOptionListProps> = ({
  optionList,
  child,
  productId,
}) => {
  // Redux.
  const dispatch = useDispatch();
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // OptionList's methods.
  const onSelectOption = (option: Option) => {
    dispatch(
      selectOption(
        id,
        optionList.optionListId,
        option.lineNr,
        optionList.multiple,
      ),
    );
  };

  return (
    <OptionListWrapper child={child}>
      <OptionListTitle>{optionList.name}</OptionListTitle>
      <Options>
        {optionList.options.map((option: Option) => (
          <TextOption
            key={option.lineNr}
            option={option}
            onClick={() => onSelectOption(option)}
          />
        ))}
      </Options>
    </OptionListWrapper>
  );
};

export default TextOptionList;
