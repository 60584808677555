import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ProductState } from '../../store/product/types';
import { getTotalPrice, getOptionLists } from '../../utils/optionList';
import { Configuration } from '../../store/configurator/types';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import { useParams } from 'react-router-dom';

numeral.locale('nl-nl');

interface PriceProps {
  isOpen: boolean;
  productId?: number;
}

const Wrapper = styled.div<PriceProps>`
  font-size: 1.625rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  transition: color 0.4s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${({ theme }) => theme.colors.secondary};
    `}

  @media (max-width: 800px) {
    order: 3;
  }
`;

const Price: FC<PriceProps> = ({ isOpen, productId }) => {
  // Redux:
  const product = useSelector<RootState, ProductState>(
    ({ product }) => product,
  );

  const id = productId ?? parseInt((useParams() as { id: string }).id);

  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const { loading, error, optionLists } = getOptionLists(configurations, id);

  // Price's methods:
  const getBasePrice = () => {
    if (loading || error) {
      return 0;
    }

    return product.products.find((product) => product.id === id)?.price || 0;
  };

  const price = getTotalPrice(getBasePrice(), optionLists);

  return <Wrapper isOpen={isOpen}>{numeral(price).format('$0,0.00')}</Wrapper>;
};

export default Price;
