import React, { FormEvent, useState } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import NormalLabel from './Label';

const Wrapper = styled(NormalLabel)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`;

const Label = styled.span`
  margin-bottom: 0.5rem;
`;

const Hidden = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
`;

const Input = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  overflow: hidden;
  font-size: 0.875rem;
`;

const Button = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 3rem;
  color: #fff;
  flex: 0 0 8rem;
  text-align: center;
`;

const Name = styled.span`
  padding: 0 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 3rem;
  min-width: 0;
`;

interface FileInputProps {
  name: string;
  label: string;
}

const FileInput = ({ name, label }: FileInputProps) => {
  const field = useField(name);
  const [file, setFile] = useState<File>();

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    if (
      event &&
      event.currentTarget.files &&
      event.currentTarget.files.length > 0
    ) {
      const file = event.currentTarget.files[0];
      setFile(file);
      field[2].setValue(file);
    }
  };

  return (
    <Wrapper>
      <Hidden type="file" onChange={handleChange} />
      <Label>{label}</Label>
      <Input>
        <Button>Bestand kiezen</Button>
        <Name>{file && file.name ? file.name : 'Geen bestand gekozen'}</Name>
      </Input>
    </Wrapper>
  );
};

export default FileInput;
