import React, { FC } from 'react';
import styled from 'styled-components';
import { Box } from '../molecules/Layout';
import { BOX_SIZE_SMALL } from '../molecules/Layout/Box';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { InputGroup, Label, Input, Error } from '../molecules/Form';
import { BoxButtonButton } from '../atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setDiscountCode, resetDiscountCode } from '../../store/order/actions';
import { RootState } from '../../store';

interface DiscountProps {
  show: boolean;
  toggle: () => void;
}

const Wrapper = styled.div<{ show: boolean }>`
  position: absolute;
  display: ${({ show }) => (show ? 'block' : 'none')};
  margin: 3rem 0 0 0;
  z-index: 1;
`;

// Formik
interface DiscountValues {
  totalPriceDiscount: number;
}

const Discount: FC<DiscountProps> = ({ show, toggle }) => {
  // Redux:
  const dispatch = useDispatch();
  const totalPriceDiscount = useSelector<RootState, number | undefined>(
    ({ order }) => order.totalPriceDiscount,
  );

  // Discount's methods.
  const onSubmit = async (values: DiscountValues) => {
    if (values.totalPriceDiscount === 0) {
      dispatch(resetDiscountCode());
    } else {
      dispatch(setDiscountCode(values.totalPriceDiscount));
    }
    toggle();
  };

  const initialValues: DiscountValues = {
    totalPriceDiscount: totalPriceDiscount ?? 0
  };

  return (
    <Wrapper show={show}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box size={BOX_SIZE_SMALL}>
              <InputGroup>
                <Label htmlFor="totalPriceDiscount">Korting</Label>
                <Field
                  name="totalPriceDiscount"
                  placeholder="Korting"
                  type="number"
                  as={Input}
                />
                <ErrorMessage name="totalPriceDiscount" component={Error} />
              </InputGroup>
            </Box>
            <BoxButtonButton
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Toepassen
            </BoxButtonButton>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default Discount;
