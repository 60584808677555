export const getImageValueExtention = (fileName: string) => {
  return fileName.split('.').pop();
};

export const getImageValueSrc = (
  fileName: string,
  fileHash: string,
  width: number,
  height: number,
) => {
  return `${process.env.REACT_APP_CDN_ROOT}${fileHash}.${getImageValueExtention(
    fileName,
  )}?thumb=true&resize=${width}x${height}&background=none&gravity=center&crop=${width}x${height}&extent=${width}x${height}&optimize=true&progressive=true`;
};
