import styled, { css } from 'styled-components';
import React, { FC } from 'react';
import LoadingIcon from 'mdi-react/LoadingIcon';

interface ButtonProps {
  icon?: string;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primary?: boolean;
  light?: boolean;
}

const Wrapper = styled.button<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.gray[50]};
  border: none;
  line-height: 2rem;
  border-radius: 1rem;
  padding: 0 1rem;
  color: ${({ theme }) => theme.colors.gray[300]};
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  font-family: 'averta';
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15rem;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  ${({ primary }) =>
    primary &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
    `}

  ${({ light }) =>
    light &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.primary};
    `}
`;

const Loading = styled.div`
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button: FC<ButtonProps> = ({ children, onClick, loading, ...props }) => (
  <Wrapper onClick={onClick} {...props}>
    <span>{children}</span>
    {loading && (
      <Loading>
        <LoadingIcon size="1rem" />
      </Loading>
    )}
  </Wrapper>
);

export default Button;
