import React, { FC } from 'react';
import styled from 'styled-components';
// import AnimatedTitle from './AnimatedTitle';

interface TitleProps {
  title?: string;
  active?: boolean;
}

// const Wrapper = styled.div<{
//   active?: boolean;
// }>`
//   height: ${({ theme }) => theme.optionLists.space};
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   transition: height 1s;
//   overflow: hidden;
//   position: relative;

//   ${({ active }) =>
//     active &&
//     css`
//       height: ${({ theme }) => theme.optionLists.activeSpace};
//     `}
// `;

// const AnimatedTitles = styled.div`
//   margin-bottom: 24px;
// `;

const H2 = styled.h2`
  margin: 0;
  font-size: 0.9375rem;
  line-height: 0.9375rem;
  opacity: 1;
  color: ${({ theme }) => theme.colors.gray[300]};
`;

// const H2 = styled.h2<{
//   active?: boolean;
// }>`
//   bottom: 10px;
//   position: absolute;
//   margin: 0;
//   font-size: 15px;
//   line-height: 15px;
//   opacity: 1;
//   color: #90a4ae;
//   transition: bottom 1s, opacity 0.5s;

//   ${({ active }) =>
//     active &&
//     css`
//       bottom: 50%;
//       opacity: 0;
//     `}
// `;

const Title: FC<TitleProps> = ({ title }) => {
  return <H2>{title}</H2>;
  // return (
  //   <Wrapper active={false}>
  //     <AnimatedTitles>
  //       <AnimatedTitle title="Kies je" active={active} />
  //       <AnimatedTitle title={title} active={active} />
  //     </AnimatedTitles>
  //     <H2 active={false}>{title}</H2>
  //   </Wrapper>
  // );
};

export default Title;
