export interface Configuration {
  optionLists: OptionList[];
  productId: number;
  isFetching: boolean;
  didInvalidate: boolean;
  queryParam?: string;
}

export interface StockOption {
  optionListId: number;
  optionLineNr: number;
  code?: string;
  stock?: number;
}

export interface StockStore {
  dealerId: string;
  storeName: string;
  storePhone: string;
  quantity: number;
  quantityDemo: number;
}

export interface Stock {
  barcode: string;
  stockQuantity: number;
  stockStores: StockStore[];
}

export interface OptionList {
  optionListId: number;
  name: string;
  lineNr: number;
  active?: boolean;
  options: Option[];
  dependencies: Dependency[];
  defaultOptionListOptionLineNr?: number;
  properties: Property[];
  multiple: boolean;
}

export interface Property {
  name: string;
  content: string;
}

export interface Option {
  optionListId: string;
  name: string;
  lineNr: number;
  order: number;
  price: number;
  textValues: TextValue[];
  imageValues: ImageValue[];
  selected?: boolean;
  stock?: Stock;
}

export interface TextValue {
  name: string;
  text: string;
}

export interface ImageValue {
  fileHash: string;
  name: string;
  fileName: string;
  color: string;
}

export interface Dependency {
  type: typeof DEPENDENCY_TYPE_VISIBILITY | typeof DEPENDENCY_TYPE_GROUP;
  dependentUponLineNr: number;
  dependentUponOptionListId: number;
  optionListLineNrs: number[];
}

export interface SendConfigurationModel {
  configuration: Blob;
  configurationUrl: string;
  shareWithCC5311: boolean;
  customerEmail: string;
  customerPhoneNumber: string;
}

// Webparts as they are configured in MyNotion, Dashboard > Instellingen > Types > Keuzelijst optie.
export const WEBPART_COLOR_HEX = 'color_hex';
export const WEBPART_COLOR_NUMBER = 'color_number';
export const WEBPART_IMAGE_REMSET = 'image_remset';
export const WEBPART_IMAGE_TIRE = 'image_tire';
export const WEBPART_IMAGE_WHEEL = 'image_wheel';
export const WEBPART_IMAGE_MASK_1 = 'image_mask_1';
export const WEBPART_IMAGE_MASK_2 = 'image_mask_2';
export const WEBPART_IMAGE_MASK_3 = 'image_mask_3';
export const WEBPART_IMAGE_GROEPSET = 'image_groepset';
export const WEBPART_IMAGE_FINISH = 'image_finish';
export const WEBPART_IMAGE_STRUCTURE = 'image_structure';
export const WEBPART_IMAGE_DETAILS = 'image_details';
export const WEBPART_IMAGE_FORK = 'image_fork';
export const WEBPART_CS_BARCODE = 'cs_barcode';
export const WEBPART_THUMBNAIL = 'thumbnail';

export const DEPENDENCY_TYPE_VISIBILITY = 'visibility';
export const DEPENDENCY_TYPE_GROUP = 'group';

export const OPTION_LIST_TYPE_IMAGE = 'OPTION_LIST_TYPE_IMAGE';
export const OPTION_LIST_TYPE_TEXT = 'OPTION_LIST_TYPE_TEXT';
export const OPTION_LIST_TYPE_COLOR = 'OPTION_LIST_TYPE_COLOR';
export const OPTION_LIST_TYPE_EMPTY = 'OPTION_LIST_TYPE_EMPTY';

export interface ConfiguratorState {
  configurations: Configuration[];
}

export const SELECT_OPTION = 'SELECT_OPTION';
export const UPDATE_OPTION = 'UPDATE_OPTION';

export const LOAD_CONFIGURATION_STARTED = 'LOAD_CONFIGURATION_STARTED';
export const LOAD_CONFIGURATION_SUCCESS = 'LOAD_CONFIGURATION_SUCCESS';
export const LOAD_CONFIGURATION_FAILURE = 'LOAD_CONFIGURATION_FAILURE';

export const LOAD_CONFIGURATION_STOCK_SUCCESS =
  'LOAD_CONFIGURATION_STOCK_SUCCESS';

export interface LoadConfigurationStarted {
  type: typeof LOAD_CONFIGURATION_STARTED;
  payload: {
    productId: number;
  };
}

export interface LoadConfigurationSucces {
  type: typeof LOAD_CONFIGURATION_SUCCESS;
  payload: {
    productId: number;
    optionLists: OptionList[];
    order?:
    | { [key: number]: number }
    | { optionListId: number; optionId: number }[];
  };
}

export interface LoadConfigurationFailure {
  type: typeof LOAD_CONFIGURATION_FAILURE;
  payload: {
    productId: number;
  };
}

export interface LoadConfigurationStockSucces {
  type: typeof LOAD_CONFIGURATION_STOCK_SUCCESS;
  payload: {
    productId: number;
    options: StockOption[];
    stocks: Stock[];
  };
}

export interface SelectOption {
  type: typeof SELECT_OPTION;
  payload: {
    productId: number;
    optionListId: number;
    optionLineNr: number;
    multiple: boolean;
  };
}

export interface UpdateOption {
  type: typeof UPDATE_OPTION;
  payload: {
    productId: number;
    optionListId: number;
    option: Option;
  };
}

export type ConfiguratorActionTypes =
  | SelectOption
  | UpdateOption
  | LoadConfigurationStarted
  | LoadConfigurationSucces
  | LoadConfigurationFailure
  | LoadConfigurationStockSucces;
