import { Dispatch } from 'redux';
import api from '../../api';
import {
  ConfiguratorActionTypes,
  LOAD_CONFIGURATION_FAILURE,
  LOAD_CONFIGURATION_STARTED,
  LOAD_CONFIGURATION_SUCCESS,
  LOAD_CONFIGURATION_STOCK_SUCCESS,
  Option,
  OptionList,
  Stock,
  StockOption,
  SELECT_OPTION,
  SendConfigurationModel,
  UPDATE_OPTION,
  WEBPART_CS_BARCODE,
} from './types';
import queryString from 'query-string';

export const loadOptionLists = (
  productId: number,
  order?: { [key: number]: number } | { optionListId: number; optionId: number }[],
) => {
  return async (dispatch: Dispatch) => {
    dispatch(loadOptionListsStarted(productId));

    try {
      const optionlists = await api.get<OptionList[]>(
        `${process.env.REACT_APP_API_ROOT}webshop/productproperty/get/${process.env.REACT_APP_SITE_ID}/${productId}`,
      );

      dispatch(loadOptionListsSuccess(productId, optionlists.data, order));
    } catch (e) {
      dispatch(loadOptionListsFailure(productId));
    }
  };
};

export const loadStock = (productId: number, optionLists: OptionList[]) => {
  return async (dispatch: Dispatch) => {
    const stockOptions = optionLists.reduce((a: StockOption[], c) => {
      const options: StockOption[] = c.options.map((option) => ({
        optionListId: c.optionListId,
        optionLineNr: option.lineNr,
        code: option.textValues.find(
          (texValue) => texValue.name === WEBPART_CS_BARCODE,
        )?.text,
      }));

      return [...a, ...options];
    }, []);

    const query = queryString.stringify({
      barcodes: stockOptions.map((stockOption) => stockOption.code),
    });

    const response = await api.get(`api/cyclesoftware/stockinfo?${query}`);

    dispatch(
      loadOptionListsStockSuccess(productId, stockOptions, response.data),
    );
  };
};

export const sendToCustomer = (
  sendConfigurationModel: SendConfigurationModel,
) => {
  return async () => {
    const fd = new FormData();
    fd.append(
      'configurationUrl',
      sendConfigurationModel.configurationUrl || '',
    );
    fd.append(
      'shareWithCC5311',
      sendConfigurationModel.shareWithCC5311 ? 'true' : 'false',
    );
    fd.append('customerEmail', sendConfigurationModel.customerEmail || '');
    fd.append(
      'customerPhoneNumber',
      sendConfigurationModel.customerPhoneNumber || '',
    );
    fd.append(
      'configuration',
      sendConfigurationModel.configuration,
      'CC5311.pdf',
    );
    const response = await api.post<SendConfigurationModel>(
      `api/Configuration/SendConfigurationToCustomer`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    // TODO: handle errors
    return response;
  };
};

export const loadOptionListsStarted = (
  productId: number,
): ConfiguratorActionTypes => ({
  type: LOAD_CONFIGURATION_STARTED,
  payload: {
    productId,
  },
});

export const loadOptionListsSuccess = (
  productId: number,
  optionLists: OptionList[],
  order?: { [key: number]: number } | { optionListId: number; optionId: number }[],
): ConfiguratorActionTypes => ({
  type: LOAD_CONFIGURATION_SUCCESS,
  payload: {
    productId,
    optionLists,
    order,
  },
});

export const loadOptionListsFailure = (
  productId: number,
): ConfiguratorActionTypes => ({
  type: LOAD_CONFIGURATION_FAILURE,
  payload: {
    productId,
  },
});

export const loadOptionListsStockSuccess = (
  productId: number,
  options: StockOption[],
  stock: Stock[],
): ConfiguratorActionTypes => ({
  type: LOAD_CONFIGURATION_STOCK_SUCCESS,
  payload: {
    productId,
    options,
    stocks: stock,
  },
});

export const selectOption = (
  productId: number,
  optionListId: number,
  optionLineNr: number,
  multiple: boolean,
): ConfiguratorActionTypes => ({
  type: SELECT_OPTION,
  payload: {
    productId,
    optionListId,
    optionLineNr,
    multiple,
  },
});

export const updateOption = (
  productId: number,
  optionListId: number,
  option: Option,
): ConfiguratorActionTypes => ({
  type: UPDATE_OPTION,
  payload: {
    productId,
    optionListId,
    option,
  },
});
