export interface OverviewState {
  showing: boolean;
}

export const SET_SHOWING = 'SET_SHOWING';
export const TOGGLE_SHOWING = 'TOGGLE_SHOWING';

export interface SetShowing {
  type: typeof SET_SHOWING;
  payload: {
    showing: boolean;
  };
}

export interface ToggleShowing {
  type: typeof TOGGLE_SHOWING;
}

export type OverviewActionTypes = SetShowing | ToggleShowing;
