import styled from 'styled-components';

const Logo = styled.img.attrs({
  src: '/configurator/apex.png',
})`
  height: 15px;
  width: auto;

  @media (max-width: 1040px) {
    height: 10px;
  }
`;

export default Logo;
