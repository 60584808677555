import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  font-size: 0.75rem;
  line-height: 0.75rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[300]};
`;

const Quantity = styled.span`
  display: inline-flex;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #21ac36;
  border-radius: 50%;
  justify-content: center;
  font-size: 0.75rem;
  align-items: center;
  color: #fff;
  font-weight: bold;
  margin-left: 0.5rem;
`;

const Stock: FC = ({ children }) => {
  return (
    <Wrapper>
      Voorraad gekozen product <Quantity>{children}</Quantity>
    </Wrapper>
  );
};

export default Stock;
