import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { Input, Label } from '../../components/molecules/Form';
import { Box } from '../../components/molecules/Layout';

interface UserValues {
    initial: string;
    firstName: string;
    insertion: string;
    lastName: string;
    street: string;
    houseNumber?: number;
    houseNumberAddition: string;
    zipCode: string;
    place: string;
    number: string;
    email: string;
}

const User: FC = () => {
    const { id } = useParams();
    const { state } = useLocation();

    // Order's methods:
    const onSubmit = (values: UserValues) => {
        // console.log(values);
    };

    // Formik:
    // @ts-ignore
    const initialValues: UserValues = (state && state.initialValues) || {
        initial: '',
        firstName: '',
        insertion: '',
        lastName: '',
        street: '',
        houseNumberAddition: '',
        zipCode: '',
        place: '',
        number: '',
        email: '',
    };

    return (
        <Grid>
            <Row>
                <Col md={4}>1</Col>
                <Col md={8}>
                    <Formik onSubmit={onSubmit} initialValues={initialValues}>
                        {({ values }) => (
                            <Form>
                                <Link
                                    to={{
                                        pathname: `/${id}/bestelling`,
                                        state: { initialValues: values },
                                    }}
                                >
                                    Terug
                </Link>
                                <Box>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Label htmlFor="initial">Voorletter(s)</Label>
                                            <Field
                                                name="initial"
                                                placeholder="Voorletter(s)"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Label htmlFor="firstName">Voornaam</Label>
                                            <Field
                                                placeholder="Voornaam"
                                                name="firstName"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Label htmlFor="insertion">Tussenvoegsel</Label>
                                            <Field
                                                name="insertion"
                                                placeholder="Tussenvoegsel"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Label htmlFor="lastName">Achternaam</Label>
                                            <Field
                                                name="lastName"
                                                placeholder="Achternaam"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <Label htmlFor="street">Straatnaam</Label>
                                            <Field
                                                name="street"
                                                placeholder="Straatnaam"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label htmlFor="houseNumber">Huisnummer</Label>
                                            <Field
                                                name="houseNumber"
                                                placeholder="Huisnummer"
                                                type="number"
                                                as={Input}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label htmlFor="houseNumberAddition">Toevoeging</Label>
                                            <Field
                                                name="houseNumberAddition"
                                                placeholder="Toevoeging"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Label htmlFor="zipCode">Postcode</Label>
                                            <Field
                                                name="zipCode"
                                                placeholder="Postcode"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label htmlFor="place">Woonplaats</Label>
                                            <Field
                                                name="place"
                                                placeholder="Woonplaats"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Label htmlFor="number">Telefoonnummer</Label>
                                            <Field
                                                name="number"
                                                placeholder="Telefoonnummer"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Label htmlFor="email">E-mail</Label>
                                            <Field
                                                name="email"
                                                placeholder="E-mail"
                                                type="text"
                                                as={Input}
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Grid>
    );
};

export default User;
