import { cloneDeep } from 'lodash';
import {
  Configuration,
  DEPENDENCY_TYPE_GROUP,
  DEPENDENCY_TYPE_VISIBILITY,
  ImageValue,
  OptionList,
  OPTION_LIST_TYPE_COLOR,
  OPTION_LIST_TYPE_EMPTY,
  OPTION_LIST_TYPE_IMAGE,
  OPTION_LIST_TYPE_TEXT,
  WEBPART_COLOR_HEX,
  WEBPART_CS_BARCODE,
  WEBPART_IMAGE_DETAILS,
  WEBPART_IMAGE_FINISH,
  WEBPART_IMAGE_FORK,
  WEBPART_IMAGE_GROEPSET,
  WEBPART_IMAGE_MASK_1,
  WEBPART_IMAGE_MASK_2,
  WEBPART_IMAGE_MASK_3,
  WEBPART_IMAGE_REMSET,
  WEBPART_IMAGE_STRUCTURE,
  WEBPART_IMAGE_TIRE,
  WEBPART_IMAGE_WHEEL,
  WEBPART_THUMBNAIL,
} from '../store/configurator/types';

export const getOptionListImageValues = (
  optionLists: OptionList[],
): ImageValue[] => {
  return optionLists
    .reduce((a: ImageValue[], c) => {
      const visible = isVisibleOptionList(optionLists, c.lineNr);
      const option = c.options.find((option) => option.selected);

      if (visible && option) {
        const imageValues = option.imageValues.filter((imageValue) => {
          return imageValue.name.startsWith('image');
        });

        return [...imageValues, ...a];
      }

      return a;
    }, [])
    .sort(
      (a, b) =>
        order.findIndex((imageValue) => imageValue === a.name) -
        order.findIndex((imageValue) => imageValue === b.name),
    );
};

export const getOptionListsWithDefaultValues = (
  optionLists: OptionList[],
  order?:
    | { [key: number]: number }
    | { optionListId: number; optionId: number }[],
  hasConfiguration?: boolean
): OptionList[] => {
  const newOptionLists = cloneDeep(optionLists);

  newOptionLists.forEach((optionList) => {
    // this is to prevent optionlist from juming back to previous option. Quite hacky.
    if (hasConfiguration && order && !Array.isArray(order) && optionList.options.some(o => o.selected != null)) {
      return;
    }

    if (order) {
      optionList.options.forEach((option) => {
        option.selected = false;
      });
    }

    // When order from params.
    if (!Array.isArray(order)) {
      if (order?.[optionList.optionListId]) {
        const lineNr = order[optionList.optionListId];
        const option = optionList.options.find(
          (option) => option.lineNr === lineNr,
        );

        if (option) {
          option.selected = true;
        }
      }
    } else {
      order.forEach((entry) => {
        if (entry.optionListId === optionList.optionListId) {
          const option = optionList.options.find(
            (option) => option.lineNr === entry.optionId,
          );

          if (option) {
            option.selected = true;
          }
        }
      });
    }

    const selectedOption = optionList.options.find((option) => option.selected);

    if (!selectedOption) {
      if (optionList.defaultOptionListOptionLineNr) {
        optionList.options.find(
          (option) =>
            option.lineNr === optionList.defaultOptionListOptionLineNr,
        )!.selected = true;
      } else if (optionList.options.length) {
        optionList.options[0].selected = true;
      }
    }
  });

  return newOptionLists;
};

// @ts-ignore
const order = [
  WEBPART_IMAGE_REMSET,
  WEBPART_IMAGE_TIRE,
  WEBPART_IMAGE_WHEEL,
  WEBPART_IMAGE_FORK,
  WEBPART_IMAGE_MASK_1,
  WEBPART_IMAGE_MASK_2,
  WEBPART_IMAGE_MASK_3,
  WEBPART_IMAGE_FINISH,
  WEBPART_IMAGE_STRUCTURE,
  WEBPART_IMAGE_DETAILS,
  WEBPART_IMAGE_GROEPSET,
  WEBPART_CS_BARCODE,
];

export const getDependentOptionLists = (
  optionLists: OptionList[],
  optionListLineNr: number,
) => {
  return cloneDeep(optionLists).reduce((a: OptionList[], c) => {
    const optionList = getOptionListByLineNr(optionLists, optionListLineNr);

    if (
      optionList &&
      isVisibleOptionList(optionLists, c.lineNr) &&
      c.dependencies.some((dependency) => {
        return (
          dependency.type === DEPENDENCY_TYPE_GROUP &&
          dependency.dependentUponLineNr === optionList.lineNr
        );
      })
    ) {
      return [...a, c];
    }

    return a;
  }, []);
};

export const getDependentOptionListPricesRecursive = (
  optionLists: OptionList[],
  optionListLineNr: number
): number => {
  const dependencies = getDependentOptionLists(optionLists, optionListLineNr);
  const option = getOptionListByLineNr(optionLists, optionListLineNr)?.options.find(
    (option) => option.selected,
  );

  let price = option?.price || 0;

  dependencies.forEach((dependency) => {
    price += getDependentOptionListPricesRecursive(
      optionLists,
      dependency.lineNr
    );
  });

  return price;
};

export const getVisibleOptionLists = (optionLists: OptionList[]) =>
  optionLists.filter((optionList) =>
    isVisibleOptionList(optionLists, optionList.lineNr),
  );

export const isVisibleOptionList = (
  optionLists: OptionList[],
  optionListLineNr: number,
): boolean => {
  const optionList = getOptionListByLineNr(optionLists, optionListLineNr);

  if (optionList) {
    const visibleDependencies = optionList.dependencies.filter(
      (dependency) => dependency.type === DEPENDENCY_TYPE_VISIBILITY,
    );

    // When there are no visible dependencies.
    if (!visibleDependencies.length) {
      return true;
    }

    const visible = visibleDependencies.every((d) => {
      const dependentOptionList = getOptionListByLineNr(
        optionLists,
        d.dependentUponLineNr,
      );

      const dependentOptions = dependentOptionList?.options.filter((option) => {
        return d.optionListLineNrs.includes(option.lineNr);
      });

      return (
        dependentOptions?.some((option) => option.selected) &&
        // Check if the parent is visible, recursion.
        isVisibleOptionList(optionLists, d.dependentUponLineNr)
      );
    });

    return visible;
  }

  return false;
};

export const getMaskColor = (optionLists: OptionList[], index: number) => {
  const colorOptionLists = optionLists.filter((optionList) => {
    return (
      isVisibleOptionList(optionLists, optionList.lineNr) &&
      getOptionListType(optionLists, optionList.optionListId) ===
      OPTION_LIST_TYPE_COLOR
    );
  });

  const color = colorOptionLists[index]?.options
    .find((option) => option.selected)
    ?.textValues.find(
      (textValue) => textValue.name === WEBPART_COLOR_HEX,
    )?.text;

  return color || '#000';
};

interface GetOptionLists {
  loading: boolean;
  error: boolean;
  optionLists: OptionList[];
}

export const getOptionLists = (
  configurations: Configuration[],
  projectId?: number | string,
): GetOptionLists => {
  // @ts-ignore, string is assignable to isNaN.
  projectId = projectId && !isNaN(projectId) ? parseInt(projectId) : projectId;

  const configuration = configurations.find(
    (configuration) => configuration.productId === projectId,
  );

  if (!configuration) {
    return { loading: true, error: false, optionLists: [] };
  }

  if (configuration.isFetching || configuration.didInvalidate) {
    return {
      loading: configuration.isFetching,
      error: configuration.didInvalidate,
      optionLists: configuration.optionLists,
    };
  }

  return {
    loading: false,
    error: false,
    optionLists: configuration.optionLists,
  };
};

export const getOptionList = (
  optionLists: OptionList[],
  optionListId: number,
): OptionList | undefined => {
  return optionLists.find((ol) => ol.optionListId === optionListId);
};

export const getOptionListByLineNr = (
  optionLists: OptionList[],
  optionListLineNr: number,
): OptionList | undefined => {
  return optionLists.find((ol) => ol.lineNr === optionListLineNr);
};

export const getOptionListType = (
  optionLists: OptionList[],
  optionListId: number,
):
  | typeof OPTION_LIST_TYPE_IMAGE
  | typeof OPTION_LIST_TYPE_TEXT
  | typeof OPTION_LIST_TYPE_COLOR
  | typeof OPTION_LIST_TYPE_EMPTY => {
  const optionList = getOptionList(optionLists, optionListId);

  if (
    optionList?.options.some((option) =>
      option.textValues.some((textValue) => {
        return textValue.name === WEBPART_COLOR_HEX;
      }),
    )
  ) {
    return OPTION_LIST_TYPE_COLOR;
  }

  if (
    !optionList?.dependencies.length ||
    optionList?.options.some((option) =>
      option.imageValues.some((imageValue) =>
        imageValue.name.startsWith('image'),
      ),
    ) ||
    optionList?.options.some((option) =>
      option.imageValues.some(
        (imageValue) => imageValue.name === WEBPART_THUMBNAIL,
      ),
    )
  ) {
    return OPTION_LIST_TYPE_IMAGE;
  }

  return OPTION_LIST_TYPE_TEXT;
};

export const getTotalPrice = (basePrice: number, optionLists: OptionList[]) => {
  return (
    basePrice +
    getVisibleOptionLists(optionLists).reduce((a, c) => {
      const selectedOptions = c.options.filter((option) => option.selected);

      if (selectedOptions.length === 0) {
        return 0;
      }

      const selectedOptionPrices = selectedOptions.reduce(
        (a, c) => a + c.price,
        0,
      );

      return a + selectedOptionPrices;
    }, 0)
  );
};

export const getSelectedOptionListsAsQueryParams = (
  optionLists: OptionList[],
) => {
  return getVisibleOptionLists(optionLists)
    .reduce((a: string[], c) => {
      const selectedOptions = c.options.filter((option) => option.selected);

      if (selectedOptions.length > 0) {
        const optionValues = selectedOptions.reduce(
          (a: string[], c) => [...a, `${c.optionListId}=${c.lineNr}`],
          [],
        );

        return [...a, ...optionValues];
      }

      return a;
    }, [])
    .join('&');
};
