import styled from 'styled-components';
import { darken } from 'polished';

interface ColorProps {
  color: string;
  selected?: boolean;
}

const Color = styled.div.attrs(({ selected }: ColorProps) => ({
  className: selected && 'selected',
}))<ColorProps>`
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  background: ${({ color }) =>
    `linear-gradient(45deg, ${color}, ${darken(0.2, color)})`};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  justify-content: center;
  border: 0 solid ${({ color }) => darken(0.1, color)};
  transition: border 0.2s;

  &:hover,
  &.selected {
    border-width: 3px;
  }
`;

export default Color;
