import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import {
  Configuration,
  Option,
  OptionList,
  OPTION_LIST_TYPE_COLOR,
} from '../../../store/configurator/types';
import {
  getOptionLists,
  getOptionListType,
  getDependentOptionListPricesRecursive,
  getDependentOptionLists,
} from '../../../utils/optionList';
import { Dependencies, Dependency, Price, Td, Title, Tr } from './styles';

interface ImageOptionLineProps {
  optionList: OptionList;
  selectedOption?: Option;
  authorized?: boolean;
  productId?: number;
}

const renderImageOptionLineDepencyNames = (
  optionList: OptionList,
  optionLists: OptionList[],
  authorized?: boolean,
) => {
  const dependentOptionLists = getDependentOptionLists(
    optionLists,
    optionList.lineNr,
  );

  const options = dependentOptionLists.reduce((a: string[], c) => {
    const option = c.options.find((option) => option.selected);
    const type = getOptionListType(optionLists, c.optionListId);

    if (type === OPTION_LIST_TYPE_COLOR) {
      const color = getDependentOptionLists(optionLists, c.lineNr)[0];
      const option = color?.options.find((option) => option.selected);

      if (option) {
        let name = option.name;

        if (authorized) {
          const code = option.textValues.find(
            (textValue) => textValue.name === 'color_number',
          )?.text;

          if (code) {
            name += ` (${code})`;
          }
        }

        return [...a, name];
      }
    }

    if (option) {
      return [...a, option.name];
    }

    return a;
  }, []);

  if (options.length) {
    return (
      <Dependencies>
        {options.map((option, index) => {
          return <Dependency key={index}>{option}</Dependency>;
        })}
      </Dependencies>
    );
  }

  return null;
};

const renderImageOptionLineDepencyPrices = (
  optionList: OptionList,
  optionLists: OptionList[],
) => {
  const dependentOptionLists = getDependentOptionLists(
    optionLists,
    optionList.lineNr,
  );

  const price = dependentOptionLists.reduce((a: number, c) => {
    return (
      a + getDependentOptionListPricesRecursive(optionLists, c.lineNr)
    );
  }, 0);

  if (price > 0) {
    return (
      <Dependencies>
        <Dependency>{numeral(price).format('$0,0.00')}</Dependency>
      </Dependencies>
    );
  }

  return null;
};

const ImageOptionLine: FC<ImageOptionLineProps> = ({
  optionList,
  selectedOption,
  authorized,
  productId,
}) => {
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Redux.
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const { optionLists } = getOptionLists(configurations, id);

  return (
    <Tr>
      <Title>{optionList.name}</Title>
      <Td>
        {selectedOption?.name}
        {renderImageOptionLineDepencyNames(optionList, optionLists, authorized)}
      </Td>
      <Price>
        {selectedOption &&
          selectedOption.price > 0 &&
          numeral(selectedOption?.price).format('$0,0.00')}
        {renderImageOptionLineDepencyPrices(optionList, optionLists)}
      </Price>
    </Tr>
  );
};

export default ImageOptionLine;
