import { FormikErrors } from 'formik';

export const handleErrors = <T>(
  errors: { [key: string]: [string] },
  setErrors: (errors: FormikErrors<T>) => void,
): void => {
  const formikErrors = toFormikErrors(errors);
  setErrors(formikErrors);
};

export const toFormikErrors = <T>(errors: {
  [key: string]: [string];
}): FormikErrors<T> => {
  return Object.entries(errors).reduce(
    (a: FormikErrors<T>, [key, value]: [string, [string]]) => {
      a[key.toLocaleLowerCase()] = value[0];

      return a;
    },
    {},
  );
};
