import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 -10px 20px ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  margin-bottom: 6rem;
`;

const Overview: FC = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Overview;
