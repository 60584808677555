import React, { FC } from 'react';
import { OptionList } from '../../../store/configurator/types';
import { getDependentOptionLists } from '../../../utils/optionList';
import { Col, Text, Entry, Dependency } from './styles';

interface ColTitleProps {
  filteredOptionLists: OptionList[];
  optionLists: OptionList[];
}

const renderDepencyEmpties = (
  optionList: OptionList,
  optionLists: OptionList[],
) => {
  const dependentOptionLists = getDependentOptionLists(
    optionLists,
    optionList.lineNr,
  );

  if (dependentOptionLists.length) {
    return <Dependency>{' '}</Dependency>
  }

  return null;
};

const ColTitle: FC<ColTitleProps> = ({ optionLists, filteredOptionLists }) => (
  <Col>
    <Entry>
      <Text>Frame</Text>
    </Entry>
    {filteredOptionLists.map((optionList) => {
      const selectedOptions = optionList.options.filter(
        (option) => option.selected,
      );

      return selectedOptions.map(() => (
        <Entry key={optionList.optionListId}>
          <Text>{optionList.name}</Text>
          {renderDepencyEmpties(optionList, optionLists)}
        </Entry>
      ));
    })}
  </Col>
);

export default ColTitle;
