import { Field } from 'formik';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-styled-flexboxgrid';
import { RootState } from '../../store';
import { setExhangeBike } from '../../store/order/actions';
import { ExchangeBike as IExchangeBike } from '../../store/order/types';
import {
  Input,
  InputGroup,
  Label,
  Textarea,
  FileInput,
} from '../molecules/Form';
import Form from '../templates/Form';
import { uploadFile } from '../../store/order/actions';

const ExchangeBike: FC = () => {
  const { id } = useParams();
  const [saved, setSaved] = useState(false);

  // Redux:
  const dispatch = useDispatch();
  const exchangeBike = useSelector<RootState, IExchangeBike>(
    ({ order }) => order.exchangeBike,
  );

  // ExchangeBike's methdos:
  const onSubmit = async (values: IExchangeBike) => {
    dispatch(setExhangeBike(values));

    if (values.image && values.image instanceof File) {
      await dispatch(uploadFile(values));
    }

    setSaved(true);
    window.history.back();
  };

  return (
    <Form
      initialValues={exchangeBike}
      title="Inruil formulier invullen"
      backRoute={`/${id}/bestelling`}
      onSubmit={onSubmit}
      primarySubmitButton={saved}
    >
      <Row>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="category">Categorie</Label>
            <Field
              name="category"
              placeholder="Categorie"
              type="text"
              as={Input}
            />
          </InputGroup>
        </Col>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="brand">Merk</Label>
            <Field name="brand" placeholder="Type" type="text" as={Input} />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="type">Type</Label>
            <Field name="type" placeholder="Type" type="text" as={Input} />
          </InputGroup>
        </Col>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="color">Kleur</Label>
            <Field placeholder="Kleur" name="color" type="text" as={Input} />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="frame">Frame</Label>
            <Field name="frame" placeholder="Type" type="text" as={Input} />
          </InputGroup>
        </Col>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="frameHeight">Framehoogte</Label>
            <Field
              placeholder="Framehoogte"
              name="frameHeight"
              type="number"
              title="cm"
              as={Input}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="frameNumber">Framenummer</Label>
            <Field
              name="frameNumber"
              placeholder="Framenummer"
              type="string"
              as={Input}
            />
          </InputGroup>
        </Col>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="modelYear">Modeljaar</Label>
            <Field
              placeholder="Modeljaar"
              name="modelYear"
              type="number"
              as={Input}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <InputGroup>
            <FileInput label="Foto van de fiets" name="image" />
          </InputGroup>
        </Col>
        <Col xs={12} sm={6}>
          <InputGroup>
            <Label htmlFor="price">Prijs</Label>
            <Field
              name="price"
              placeholder="Prijs"
              type="number"
              title="incl. BTW"
              as={Input}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12}>
          <InputGroup>
            <Label htmlFor="description">Lange omschrijving</Label>
            <Field
              rows={6}
              name="description"
              placeholder="Lange omschrijving"
              type="text"
              as={Textarea}
            />
          </InputGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default ExchangeBike;
