/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import {
  DEPENDENCY_TYPE_GROUP,
  Configuration as IConfiguration,
} from '../../store/configurator/types';
import OptionList, { ProductOptionList } from '../molecules/OptionList';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { getOptionLists, isVisibleOptionList } from '../../utils/optionList';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../api';
import { loadStock } from '../../store/configurator/actions';

const Wrapper = styled.div`
  @media (max-width: 1040px) {
    margin: 300px 0 4rem 0;
    display: flex;
    justify-content: center;
  }
`;

const Container = styled.div`
  position: relative;
  width: ${({ theme }) => theme.optionLists.width};
  float: right;
  padding: 0 32px;

  @media (max-width: 1040px) {
    float: none;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
  }
`;

export interface ConfigurationProps {
  hideProducts?: boolean;
  productId?: number;
  hideBottomButton?: boolean;
}

interface IAuthorizationData {
  success: boolean;
  claims: { type: string, value: string }[];
}

const Configuration: FC<ConfigurationProps> = ({ hideProducts, productId, hideBottomButton }) => {
  // State.
  const [authorizing, setAuthorizing] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  // Params:
  const id = productId ?? parseInt((useParams() as { id: string }).id);
  const history = useHistory();

  // Redux:
  const dispatch = useDispatch();
  const configurations = useSelector<RootState, IConfiguration[]>(
    ({ configurator }) => configurator.configurations,
  );

  // const anyConfiguratorsLoading = configurations.some(c => c.isFetching);
  // Configuration methods.
  const isAuthorized = async () => {
    if (authorizing) {
      return;
    }

    setAuthorizing(true);

    api
      .get<IAuthorizationData>(`${process.env.REACT_APP_API_ROOT}account/authorized`)
      .then(() => {
        setAuthorized(true);
      })
      .catch(() => {
        setAuthorized(false);
      })
      .finally(() => {
        setAuthorizing(false)
      });
  };

  const setStock = async () => {
    if (authorized) {
      dispatch(loadStock(id, optionLists));
    }
  };

  useEffect(() => {
    isAuthorized();
  }, [id]);

  useEffect(() => {
    setStock();
  }, [id, authorized]);

  // Configuration's lifecycle.
  useEffect(() => {
    const configuration = configurations.find(
      (configuration) => configuration.productId === id,
    );

    if (configuration?.queryParam) {
      history.push(`${location.pathname}?${configuration.queryParam}`);
    }
  }, [id, configurations]);

  const getLast = (index: number) => index === filteredOptionLists.length - 1;

  // Render:
  const { optionLists } = getOptionLists(configurations, id);
  const filteredOptionLists = optionLists.filter((optionList) => {
    const visible = isVisibleOptionList(optionLists, optionList.lineNr);

    return (
      visible &&
      !optionList.dependencies.some((dependency) => {
        return dependency.type === DEPENDENCY_TYPE_GROUP;
      })
    );
  });

  return (
    <Wrapper>
      <Container>
        {!hideProducts && (
          <Element key={'frame'} name={'frame'}>
            <ProductOptionList />
          </Element>
        )}
        {filteredOptionLists.map((optionList, index) => (
          <Element
            key={optionList.optionListId}
            name={optionList.name.toLowerCase()}
          >
            <OptionList
              productId={productId}
              authorized={authorized}
              child={false}
              key={optionList.optionListId}
              optionList={optionList}
              last={getLast(index)}
              hideBottomButton={hideBottomButton}
            />
          </Element>
        ))}
      </Container>
    </Wrapper>
  );
};

export default Configuration;
