import React, { FC, useEffect, useRef, useState } from 'react';
import Button from '../atoms/Button';
import styled from 'styled-components';
import ShareModal from './ShareModal';

const Wrapper = styled.div`
  @media (max-width: 800px) {
    order: 2;
    margin-bottom: 1rem;
  }
`;

const ShareDesign: FC = () => {
  const [show, setShow] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }

    setShow(false);
  };

  const addMouseDownListener = () => {
    document.addEventListener('mousedown', handleClickOutside);
  };

  const removeMouseDownListener = () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };

  // ShareDesign's lifecycle:
  useEffect(() => {
    if (show) {
      addMouseDownListener();
    } else {
      removeMouseDownListener();
    }

    return removeMouseDownListener;
  }, [show]);

  return (
    <Wrapper ref={node}>
      <ShareModal show={show} toggle={() => setShow(!show)} />
      <Button primary onClick={() => setShow(!show)}>
        Ontwerp delen
      </Button>
    </Wrapper>
  );
};

export default ShareDesign;
