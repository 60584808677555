import {
  OverviewState,
  OverviewActionTypes,
  SET_SHOWING,
  SetShowing,
  TOGGLE_SHOWING,
} from './types';

const initialState: OverviewState = {
  showing: false,
};

const setShowing = (state: OverviewState, action: SetShowing) => {
  return {
    ...state,
    showing: action.payload.showing,
  };
};

const toggleShowing = (state: OverviewState) => {
  return {
    ...state,
    showing: !state.showing,
  };
};

export const overviewReducer = (
  state = initialState,
  action: OverviewActionTypes,
): OverviewState => {
  switch (action.type) {
    case SET_SHOWING:
      return setShowing(state, action);
    case TOGGLE_SHOWING:
      return toggleShowing(state);
    default:
      return state;
  }
};
