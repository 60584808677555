import React, { FC } from 'react';
import styled from 'styled-components';
import {
  Option as IOption,
  Configuration,
} from '../../../store/configurator/types';
import { ColorOption } from '../../atoms/Option';
import { useDispatch, useSelector } from 'react-redux';
import { selectOption } from '../../../store/configurator/actions';
import * as styles from './styles';
import { OptionListProps } from './OptionList';
import ColorFinishOptionList from './ColorFinishOptionList';
import {
  getDependentOptionLists,
  getOptionLists,
} from '../../../utils/optionList';
import { RootState } from '../../../store';
import { useParams } from 'react-router-dom';

interface ColorOptionListProps extends OptionListProps {
  productId?: number;
}

const OptionListWrapper = styled.div`
  ${styles.OptionList}
  padding-top: 0.8125rem;
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  position: relative;
`;

const OptionListTitle = styled.h3`
  ${styles.OptionListTitle}
  padding: 0 0.3125rem;
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BottomInformation = styled.div`
  color: ${({ theme }) => theme.colors.gray[300]};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray[100]};
`;

export const Information = styled.div.attrs(({ info }: { info: string }) => ({
  'data-tip': info,
})) <{ info: string }>`
  ${styles.Information}
`;

const ColorOptionList: FC<ColorOptionListProps> = ({
  optionList,
  child,
  authorized,
  productId,
}) => {
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Redux:
  const dispatch = useDispatch();
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  // ColorOptionList's methods.
  const onSelectOption = (option: IOption) => {
    dispatch(
      selectOption(
        id,
        optionList.optionListId,
        option.lineNr,
        optionList.multiple,
      ),
    );
  };

  const newRowAIndexAfterSelectedOption = () => {
    const selectedIndex = optionList.options.findIndex((option) => {
      return option.selected;
    });

    return Math.ceil((selectedIndex + 1) / 7) * 7 - 1;
  };

  // Render:
  const { optionLists } = getOptionLists(configurations, id);
  const dependency = getDependentOptionLists(
    optionLists,
    optionList.lineNr,
  )[0];

  return (
    <OptionListWrapper className="test" child={child}>
      <OptionListTitle>{optionList.name}</OptionListTitle>
      <Options>
        {optionList.options.map((option: IOption, index: number) => (
          <>
            <ColorOption
              key={option.lineNr}
              option={option}
              onClick={() => onSelectOption(option)}
            />
            {dependency &&
              (index === newRowAIndexAfterSelectedOption() ||
                (newRowAIndexAfterSelectedOption() >
                  optionList.options.length &&
                  index === optionList.options.length - 1)) && (
                <ColorFinishOptionList
                  productId={productId}
                  optionList={dependency}
                  authorized={authorized}
                />
              )}
          </>
        ))}
      </Options>
      <BottomInformation>
        In de winkel helpt een van onze specialisten je bij het selecteren van
        de passende afwerking.
      </BottomInformation>
    </OptionListWrapper>
  );
};

export default ColorOptionList;
