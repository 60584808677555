/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ProductState } from '../../store/product/types';
import Button from '../atoms/Button';
import styled from 'styled-components';
import { useState } from 'react';
import { getOptionLists, getVisibleOptionLists } from '../../utils/optionList';
import { Configuration } from '../../store/configurator/types';
import { generatePDF } from '../organisms/OverviewPDF';

interface ConfigureProps {
  productId: number;
}

const Configure: FC<ConfigureProps> = ({ productId }) => {
  // Router:
  const [isDownloading, setIsDownloading] = useState(false);

  // Redux:
  const product = useSelector<RootState, ProductState>(
    ({ product }) => product,
  );

  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const { optionLists } = getOptionLists(configurations, productId);

  useEffect(() => {
    download();
  }, []);

  const download = async () => {
    const p = product.products.find((product) => product.id === productId);

    // console.log({ p, productId, products: product.products });

    if (!p || isDownloading) {
      return;
    }

    setIsDownloading(true);

    const pdf = await generatePDF(
      getVisibleOptionLists(optionLists),
      p,
      window.location.href,
    );

    // Download.
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(pdf);
    document.body.appendChild(link);

    link.href = url;
    link.setAttribute('download', `${productId}.pdf`);
    link.click();

    setIsDownloading(false);
  };

  return (
    <Container>
      <Button primary loading={isDownloading} onClick={download}>
        {isDownloading ? 'downloading...' : 'download'}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Configure;
