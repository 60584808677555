import React, { FC } from 'react';
import { Option as IOption } from '../../../store/configurator/types';
import styled, { css } from 'styled-components';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';

interface ColorFinishOptionProps {
  onClick?: () => void;
  option: IOption;
}

const Wrapper = styled.div`
  margin: 0 0.375rem 0.375rem;
`;

const Option = styled.div<{
  selected: boolean;
}>`
  line-height: 2rem;
  background-color: #fff;
  padding: 0 0.5rem;
  font-size: 0.9375rem;
  border-radius: ${({ theme }) => theme.borderRadius.small};

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primary};
    `}

  &:hover {
    cursor: pointer;
  }
`;

const Price = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[700]};
`;

const ColorFinishOption: FC<ColorFinishOptionProps> = ({ option, onClick }) => {
  return (
    <Wrapper>
      <Option selected={!!option.selected} onClick={onClick}>
        {option.name}
      </Option>
      <Price>{`+ ${numeral(option.price).format('$0,0.00')}`}</Price>
    </Wrapper>
  );
};

export default ColorFinishOption;
