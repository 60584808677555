import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const Overview = styled.div<{
  show: boolean;
}>`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: ${({ theme }) => theme.footer.height.medium};
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  cursor: pointer;
  position: relative;
  user-select: none;

  &:before {
    content: '';
    position: absolute;
    width: 113px;
    height: 0.25rem;
    background-color: ${rgba('#90A4AE', 0.6)};
    border-radius: 0.125rem;
    margin-top: 0.75rem;
    top: 0;
    transition: top 0.4s;
  }

  @media (max-width: 800px) {
    order: 1;
    color: ${({ show, theme }) =>
      show ? theme.colors.secondary : theme.colors.white};
  }

  ${({ show }) =>
    show &&
    css`
      &:before {
        top: -${({ theme }) => theme.overview.height};

        @media (max-width: 800px) {
          top: 0;
        }
      }
    `}
`;

export default Overview;
