import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import CheckBoldIcon from 'mdi-react/CheckBoldIcon';

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  display: flex;
  justify-content: center;
  align-items: center;

  .mdi-icon {
    display: none;
  }
`;

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:focus ~ ${CheckMark} {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.gray[100]};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  }

  &:checked ~ ${CheckMark} {
    background-color: ${({ theme }) => theme.colors.primary};

    .mdi-icon {
      display: block;
    }
  }
`;

const Wrapper = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 1.5rem;
  width: 1.5rem;
`;

const Checkbox: FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  children,
  ...props
}) => {
  return (
    <Wrapper>
      <StyledCheckbox type="checkbox" id={props.name} {...props} />
      <CheckMark>
        <CheckBoldIcon size={12} color="#fff" />
      </CheckMark>
    </Wrapper>
  );
};

export default Checkbox;
