import styled from 'styled-components';
import { Link } from 'react-scroll';

const Step = styled(Link)`
  max-width: 2rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 1rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  transition: max-width 0.3s linear;
  overflow: hidden;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;

  span.step {
    flex: 0 0 2rem;
    width: 2rem;
    text-align: center;
  }

  span.title {
    padding-right: 8px;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
  }

  &.active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    max-width: 10rem;
    width: auto;

    &:not(.active) {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export default Step;
