import React, { FC } from 'react';
import Form from '../templates/Form';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Label, Input } from '../molecules/Form';
import { Field, FormikHelpers, ErrorMessage } from 'formik';
import api from '../../api';
import { useHistory } from 'react-router-dom';
import Error from '../molecules/Form/Error';
import { handleErrors } from "../../utils/error";
import queryString from 'query-string';

interface LoginValues {
  username: string;
  password: string;
  rememberme: boolean;
}

interface QueryOptions {
  redirect?: string;
}

const Login: FC = () => {
  const history = useHistory();
  const queryOptions = queryString.parse(location.search) as QueryOptions;

  // ExchangeBike's methdos.
  const onSubmit = (
    values: LoginValues,
    { setErrors }: FormikHelpers<LoginValues>,
  ) => {
    api
      .post<LoginValues>(`api/account/cookielogin`, values)
      .then((response) => {
        if (response && response.status === 200) {
          history.replace(queryOptions.redirect ?? '/');
        }
      })
      .catch((error) => {
        const errors = error.response.data.errors;
        handleErrors<LoginValues>(errors, setErrors);
      });
  };

  // Formik.
  const initialValues: LoginValues = {
    username: '',
    password: '',
    rememberme: false,
  };

  return (
    <Form
      initialValues={initialValues}
      title="Inloggen"
      onSubmit={onSubmit}
      submitText="inloggen"
    >
      <Row>
        <Col xs={12} sm={6}>
          <Label htmlFor="username">Gebruikersnaam</Label>
          <Field
            name="username"
            placeholder="Gebruikersnaam"
            type="text"
            as={Input}
          />
          <ErrorMessage name="username" component={Error} />
        </Col>
        <Col xs={12} sm={6}>
          <Label htmlFor="password">Wachtwoord</Label>
          <Field
            placeholder="Wachtwoord"
            name="password"
            type="password"
            as={Input}
          />
          <ErrorMessage name="password" component={Error} />
        </Col>
      </Row>
    </Form>
  );
};

export default Login;
