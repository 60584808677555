import { OverviewActionTypes, SET_SHOWING, TOGGLE_SHOWING } from './types';

export const setShowing = (showing: boolean): OverviewActionTypes => ({
  type: SET_SHOWING,
  payload: {
    showing,
  },
});

export const toggleShowing = (): OverviewActionTypes => ({
  type: TOGGLE_SHOWING,
});
