import {
  OrderActionTypes,
  SET_EXCHANGE_BIKE,
  ExchangeBike,
  CartOrder,
  CdnFileResult,
  PLACE_ORDER_STARTED,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_RESET,
  SET_DISCOUNT_CODE,
  RESET_DISCOUNT_CODE,
  OrderOptions
} from './types';
import { Dispatch } from 'redux';
import api from '../../api';

export const placeOrder = (orderModel: CartOrder, orderOptions: OrderOptions) => {
  return async (dispatch: Dispatch) => {
    dispatch(placeOrderStarted());

    try {
      const response = await api.post<{ data: { orderId: number } }>(
        `${process.env.REACT_APP_API_ROOT}webshop/order/PlaceStyleCc5311/${process.env.REACT_APP_SITE_ID}`,
        {
          model: orderModel,
          options: orderOptions
        },
      );

      dispatch(placeOrderSucces(response.data.data.orderId));
    } catch (e) {
      dispatch(placeOrderFailed());
    }
  };
};

export const uploadFile = (exchangeBike: ExchangeBike) => {
  return async (dispatch: Dispatch) => {
    const fd = new FormData();
    if (!exchangeBike.image) {
      return;
    }
    fd.append('file', exchangeBike.image);

    const response = await api.post<CdnFileResult>(
      'api/Order/UploadFile',
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    if (response.status === 200 && response.data) {
      dispatch(setExhangeBike({
        ...exchangeBike,
        uploadedFile: response.data
      }))
    }

    return response;
  }
}

export const placeOrderStarted = (): OrderActionTypes => ({
  type: PLACE_ORDER_STARTED,
});

export const placeOrderSucces = (orderId: number): OrderActionTypes => ({
  type: PLACE_ORDER_SUCCESS,
  payload: {
    orderId,
  },
});

export const placeOrderFailed = (): OrderActionTypes => ({
  type: PLACE_ORDER_FAILURE
});

export const placeOrderReset = (): OrderActionTypes => ({
  type: PLACE_ORDER_RESET,
});

export const setExhangeBike = (
  exchangeBike: ExchangeBike,
): OrderActionTypes => ({
  type: SET_EXCHANGE_BIKE,
  payload: {
    exchangeBike,
  },
});

export const setDiscountCode = (discount: number): OrderActionTypes => ({
  type: SET_DISCOUNT_CODE,
  payload: {
    discount,
  },
});

export const resetDiscountCode = (): OrderActionTypes => ({
  type: RESET_DISCOUNT_CODE,
});