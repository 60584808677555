import styled from 'styled-components';

const OptionLines = styled.table`
  margin-bottom: 2.5rem;
  flex: 0 1 40rem;

  @media (max-width: 800px) {
    width: 100%;
    flex: 1;
  }
`;

export default OptionLines;
