import React, { FC } from 'react';
import styled from 'styled-components';
import { Option } from '../../../store/configurator/types';
import { ImageOption } from '../../atoms/Option';
import { useDispatch } from 'react-redux';
import { selectOption } from '../../../store/configurator/actions';
import * as styles from './styles';
import { OptionListProps } from './OptionList';
import { useParams } from 'react-router-dom';

interface ImageOptionListProps extends OptionListProps {}

const OptionListWrapper = styled.div`
  ${styles.OptionList}
`;

const OptionListTitle = styled.h3`
  ${styles.OptionListTitle}
`;

const ImageOptionList: FC<ImageOptionListProps> = ({
  optionList,
  child,
  productId,
}) => {
  // Redux.
  const dispatch = useDispatch();
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // OptionList's methods.
  const onSelectOption = (option: Option) => {
    dispatch(
      selectOption(
        id,
        optionList.optionListId,
        option.lineNr,
        optionList.multiple,
      ),
    );
  };

  // Render.
  return (
    <OptionListWrapper child={child}>
      {child && <OptionListTitle>{optionList.name}</OptionListTitle>}
      <div>
        {optionList.options.map((option: Option) => (
          <ImageOption
            key={option.lineNr}
            option={option}
            onClick={() => onSelectOption(option)}
          />
        ))}
      </div>
    </OptionListWrapper>
  );
};

export default ImageOptionList;
