import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import LoadingIcon from 'mdi-react/LoadingIcon';

interface BoxButtonButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  padding: 0 1rem;
`;

const Button = styled.button<BoxButtonButtonProps>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0 0 0.25rem 0.25rem;
  text-decoration: none;
  height: 4.5rem;
  padding: 1rem 1.5rem;
  color: #fff;
  font-size: 0.9375rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.15rem;
  box-shadow: 0 -10px 20px ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}
`;

const Loading = styled.div`
  margin-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const BoxButtonButton: FC<
  BoxButtonButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, loading, ...props }) => (
  <Wrapper>
    <Button {...props}>
      <span>{children}</span>
      {loading && (
        <Loading>
          <LoadingIcon size="1rem" />
        </Loading>
      )}
    </Button>
  </Wrapper>
);

export default BoxButtonButton;
