import styled from 'styled-components';

interface ImageLayerProps {
  uri: string;
}

const ImageLayer = styled.img.attrs(({ uri }: ImageLayerProps) => ({
  src: uri,
}))<ImageLayerProps>`
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  position: absolute
`;

export default ImageLayer;
