import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Option } from '../../../store/configurator/types';
import { getImageValueSrc } from '../../../utils/cdn';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';

numeral.locale('nl-nl');

interface ImageOptionProps {
  onClick: () => void;
  option: Option;
}

const Wrapper = styled.div<{
  selected?: boolean;
}>`
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: ${({ theme }) => theme.borderRadius.small};

  ${({ selected }) =>
    !selected &&
    css`
      border-top: 1px solid #eceff1;

      &:first-child {
        border-top: 1px solid transparent;
      }

      &:hover {
        cursor: pointer;
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      border-top: 1px solid #eceff1;
      background-color: #eceff1;

      & + & {
        border-top-color: transparent !important;
      }
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9375rem;
  flex: 1;
  min-width: 0;
`;

const Title = styled.span`
  color: #455a64;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Price = styled.span`
  color: #90a4ae;
  margin-top: 0.125rem;
`;

const Thumbnail = styled.img`
  width: 4rem;
  height: 3rem;
  margin-right: 1rem;
`;

const ImageOption: FC<ImageOptionProps> = ({ option, onClick }) => {
  const thumbnail = option.imageValues.find(
    (imageValue) => imageValue.name === 'thumbnail',
  );

  return (
    <Wrapper selected={option.selected} onClick={onClick}>
      {thumbnail && (
        <Thumbnail
          src={getImageValueSrc(
            thumbnail.fileName,
            thumbnail.fileHash,
            128,
            96,
          )}
        />
      )}
      <TextWrapper>
        <Title>{option.name}</Title>
        {option.price > 0 && (
          <Price>{numeral(option.price).format('$0,0.00')}</Price>
        )}
      </TextWrapper>
    </Wrapper>
  );
};

export default ImageOption;
