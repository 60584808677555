import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import React, { FC } from 'react';
import { Td, Tr } from './styles';
import styled from 'styled-components';

interface DiscountOptionLineProps {
  discount: number;
}

const DiscountTd = styled(Td)`
  color: ${({ theme }) => theme.colors.gray[300]};
`;

const DiscountOptionLine: FC<DiscountOptionLineProps> = ({ discount }) => {
  return (
    <Tr>
      <DiscountTd />
      <DiscountTd>Korting</DiscountTd>
      <DiscountTd>{numeral(discount).format('$0,0.00')}</DiscountTd>
    </Tr>
  );
};

export default DiscountOptionLine;
