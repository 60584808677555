import thunk from 'redux-thunk';
import { configuratorReducer } from './configurator/reducers';
import { productReducer } from './product/reducers';
import { overviewReducer } from './overview/reducers';
import { orderReducer } from './order/reducers';
import { editOrderReducer } from './editOrder/reducers';
import { globalsReducer } from './globals/reducers';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  configurator: configuratorReducer,
  product: productReducer,
  overview: overviewReducer,
  order: orderReducer,
  editOrder: editOrderReducer,
  globals: globalsReducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['editOrder'],
};

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);
