import React, { FC } from 'react';
import Bike from '../organisms/Bike';
import IntroFooter from '../organisms/IntroFooter';
import styled from 'styled-components';

interface IntroProps {
  productId: number;
}

// TODO color vari.
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #eceff1;
`;

const BikeWrapper = styled.div`
  width: 60%;
  margin: 0 20%;
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: 1920px) {
    width: 70%;
    margin: 0 15%;
  }

  @media (max-width: 1040px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const Intro: FC<IntroProps> = ({ productId }) => (
  <Wrapper>
    <BikeWrapper>
      <Bike />
    </BikeWrapper>
    <IntroFooter productId={productId} />
  </Wrapper>
);

export default Intro;
