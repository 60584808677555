import styled from 'styled-components';

const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 2rem;
  line-height: 1.8125rem;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
`;

export default H2;
