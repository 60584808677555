import { css } from 'styled-components';
import { rgba } from 'polished';

export interface OptionListProps {
  child: boolean;
}

export const OptionList = css<OptionListProps>`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.space.medium};
  box-shadow: 0 10px 20px -20px ${({ theme }) => rgba(theme.colors.secondary, 0.5)};

  ${({ child }) =>
    !child &&
    css`
      position: relative;
      z-index: 1;
    `}
`;

export const OptionListTitle = css`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.875rem;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const Information = css`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray[300]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: .5rem;

  .mdi-icon {
    width: 1rem;
    height: 1rem;
    color: #fff;
  }
`;
