import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { RootState } from '../../store';
import { toggleShowing } from '../../store/overview/actions';
import Overview from '../atoms/Overview';
import Price from '../atoms/Price';
import OverviewModal from '../organisms/OverviewModal';
import SaveOrder from '../organisms/SaveOrder';

const Wrapper = styled.div<{
    show: boolean;
    up: boolean;
}>`
  width: 100%;
  height: ${({ theme }) => theme.footer.height.medium};
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: flex;
  align-items: center;
  padding: 0 48px;
  justify-content: space-between;
  z-index: 3;
  transition: background-color 0.5s;

  ${({ show }) =>
        show &&
        css`
      background-color: ${({ theme }) => theme.colors.white};
    `}

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    height: ${({ theme }) => theme.footer.height.large};
    padding: 0 16px;
    transition: bottom 0.4s;
    bottom: ${({ theme, up }) => (up ? 0 : `-${theme.footer.height.large}`)};
  }
`;

interface FooterProps {
    productId: number;
}

// Weird marco component. Just a copy of another component, with 1 thing different.
const Footer: FC<FooterProps> = ({ productId }) => {
    // State.
    const [up, setUp] = useState(false);
    let lastScrollTop = 0;

    // Redux.
    const dispatch = useDispatch();
    const showing = useSelector<RootState, boolean>(
        ({ overview }) => overview.showing,
    );

    // Footer's methods.
    const toggleOverview = () => {
        dispatch(toggleShowing());
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setUp(scrollTop <= lastScrollTop);

        // For Mobile or negative scrolling
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    const addScrollListener = () => {
        document.addEventListener('scroll', handleScroll);
    };

    const removeScrollListener = () => {
        document.removeEventListener('scroll', handleScroll);
    };

    // Footer's lifecycle:
    useEffect(() => {
        addScrollListener();

        return removeScrollListener;
    }, []);

    // Render:
    return (
        <>
            <OverviewModal productId={productId} authorized />
            <Wrapper show={showing} up={showing || up}>
                <SaveOrder productId={productId} />
                <Overview show={showing} onClick={toggleOverview}>
                    Bekijk je overzicht
                </Overview>
                <Price productId={productId} isOpen={showing} />
            </Wrapper>
        </>
    );
};

export default Footer;
