import styled from 'styled-components';

export const Tr = styled.tr`
  white-space: nowrap;
`;

// TODO change color var.
export const Td = styled.td`
  font-size: 0.9375rem;
  padding: 0 5rem 0.5rem 0;
  line-height: 1.5rem;
  color: #455a64;
  vertical-align: top;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 580px) {
    &:first-child {
      display: none;
    }
  }

  @media (max-width: 800px) {
    padding-right: 1rem;
  }
`;

// TODO change color var.
export const Title = styled(Td)`
  color: #90a4ae;
  max-width: 6rem;
`;

export const Dependencies = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
`;

export const Price = styled(Td)`
  font-weight: 700;
  padding-right: 0;
  max-width: 5rem;

  @media (max-width: 800px) {
    text-align: right;
  }

  ${Dependencies} {
    @media (max-width: 800px) {
      justify-content: flex-end;
    }
  }
`;

export const Dependency = styled.li`
  list-style: none;
  color: #90a4ae;
  font-size: 0.75rem;
  font-weight: normal;

  &:not(:first-child):before {
    content: '•';
    display: inline-block;
    padding-right: 0.25rem;
  }

  &:not(:first-child) {
    padding-left: 0.25rem;
  }
`;
