import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Product as IProduct } from '../../store/product/types';
import { loadProducts } from '../../store/product/actions';
import { useHistory } from 'react-router-dom';

const Product: FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const products = useSelector<RootState, IProduct[]>(
    ({ product }) => product.products,
  );

  // Configure's lifecycle:
  useEffect(() => {
    dispatch(loadProducts());
  }, []);

  useEffect(() => {
    if (products.length) {
      history.push(`${products[0].id}/configureren`);
    }
  }, [products]);

  return <p>product</p>;
};

export default Product;
