import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import React, { FC } from 'react';
import { Tr, Td } from './styles';
import styled from 'styled-components';

interface ExchangeBikeOptionLineProps {
    brand: string;
    type: string;
    modelYear: number;
    price: number;
}

const DiscountTd = styled(Td)`
  color: ${({ theme }) => theme.colors.gray[300]};
`;

const ExchangeBikeOptionLine: FC<ExchangeBikeOptionLineProps> = ({ brand, type, modelYear, price }) => {
    return (
        <Tr>
            <DiscountTd />
            <DiscountTd>{`Inruilfiets ${brand} ${type} (${modelYear})`}</DiscountTd>
            <DiscountTd>{numeral(price).format('$0,0.00')}</DiscountTd>
        </Tr>
    );
};

export default ExchangeBikeOptionLine;
