import React, { FC } from 'react';
import EditOrderConfigurator from '../templates/EditOrderConfigurator';
import LoadOrder from '../templates/LoadOrder';

const EditOrder: FC = () => {
  return (
    <LoadOrder>
      {(productId) => <EditOrderConfigurator productId={productId} />}
    </LoadOrder>
  );
};

export default EditOrder;
