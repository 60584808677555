/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ProductState } from '../../store/product/types';
import { loadProducts } from '../../store/product/actions';
import { useHistory, useParams } from 'react-router-dom';
import { getOptionLists } from '../../utils/optionList';
import { Configuration } from '../../store/configurator/types';
import { loadOptionLists } from '../../store/configurator/actions';
import IntroTemplate from '../templates/Intro';

const Intro: FC = () => {
  // Router:
  const id = parseInt((useParams() as { id: string }).id);
  const history = useHistory();

  // Redux:
  const dispatch = useDispatch();
  const product = useSelector<RootState, ProductState>(
    ({ product }) => product,
  );

  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  // Intro's lifecycle:
  useEffect(() => {
    dispatch(loadProducts());
  }, []);

  useEffect(() => {
    if (!product.products.length) {
      return;
    }

    dispatch(loadOptionLists(id));

    if (!product.products.find((product) => product.id === id)) {
      history.push('/');
    }
  }, [product, id]);

  // Render:
  const { optionLists } = getOptionLists(configurations, id);

  // if (loading) {
  //   return <p>Loading</p>;
  // }

  // if (error) {
  //   return <p>Error</p>;
  // }

  // @ts-ignore
  return <IntroTemplate optionLists={optionLists} productId={parseInt(id)} />;
};

export default Intro;
