import styled, { css } from 'styled-components';

interface LabelProps {
  required?: boolean;
}

const Label = styled.label<LabelProps>`
  margin-bottom: 0.5rem;
  display: inline-block;
  color: ${({ theme }) => theme.colors.gray[300]};
  font-size: 0.875rem;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: ' *';
        color: ${({ theme }) => theme.colors.primary};
        font-weight: bold;
      }
    `}
`;

export default Label;
