import React, { FC } from 'react';
import { Col, Text, Dependency, Entry } from './styles';
import { OptionList } from '../../../store/configurator/types';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
// @ts-ignore
import styled from '@react-pdf/styled-components';
import { Product } from '../../../store/product/types';
import {
  getDependentOptionListPricesRecursive,
  getDependentOptionLists,
} from '../../../utils/optionList';

numeral.locale('nl-nl');

interface ColPriceProps {
  filteredOptionLists: OptionList[];
  optionLists: OptionList[];
  product?: Product;
}

const Price = styled(Text)`
  color: #455a64;
  font-family: 'averta-bold';
`;

const renderImageOptionLineDepencyPrices = (
  optionList: OptionList,
  optionLists: OptionList[],
) => {
  const dependentOptionLists = getDependentOptionLists(
    optionLists,
    optionList.lineNr,
  );

  const price = dependentOptionLists.reduce((a: number, c) => {
    return (
      a + getDependentOptionListPricesRecursive(optionLists, c.lineNr)
    );
  }, 0);

  if (dependentOptionLists.length) {
    return (
      <Dependency>{price ? numeral(price).format('$0,0.00') : ' '}</Dependency>
    );
  }

  return null;
};

const ColPrice: FC<ColPriceProps> = ({
  filteredOptionLists,
  optionLists,
  product,
}) => (
  <Col>
    <Entry>
      <Price>{product ? numeral(product.price).format('$0,0.00') : ' '}</Price>
    </Entry>
    {filteredOptionLists.map((optionList) => {
      const selectedOptions = optionList.options.filter(
        (option) => option.selected,
      );

      return selectedOptions.map((selectedOption) => {
        const price = selectedOption.price;

        return (
          <Entry key={optionList.optionListId}>
            <Price>{price ? numeral(price).format('$0,0.00') : ' '}</Price>
            {renderImageOptionLineDepencyPrices(optionList, optionLists)}
          </Entry>
        );
      });
    })}
  </Col>
);

export default ColPrice;
