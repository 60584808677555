import {
  Document,
  Font,
  Image,
  Page,
  pdf,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
// @ts-ignore
import styled from '@react-pdf/styled-components';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import QRCode from 'qrcode';
import React, { FC } from 'react';
import {
  DEPENDENCY_TYPE_GROUP,
  OptionList,
} from '../../store/configurator/types';
import { Product } from '../../store/product/types';
import {
  getOptionListImageValues,
  getTotalPrice,
  getVisibleOptionLists,
} from '../../utils/optionList';
import { initializeImages } from '../../utils/pdf';
import { ColPrice, ColTitle, ColValue } from '../molecules/Col';

numeral.locale('nl-nl');

// Register fonts.
Font.register({
  family: 'averta-black',
  src: '/fonts/averta-black.woff',
});

Font.register({
  family: 'averta-regular',
  src: '/fonts/averta-regular.woff',
});

Font.register({
  family: 'averta-bold',
  src: '/fonts/averta-bold.woff',
});

interface OverviewPDFProps {
  images: string[];
  optionLists: OptionList[];
  product?: Product;
  price: number;
  qrDataUrl?: string;
  discount?: number;
}

// Create styles.
const styles = StyleSheet.create({
  image: {
    objectFit: 'contain',
  },
  imageWrapper: {
    top: '50mm',
    left: 0,
    width: '100%',
    height: '120mm',
    position: 'absolute',
  },
  content: {
    margin: '15mm',
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    color: '#455a64',
  },
});

const Top = styled.View`
  width: 100%;
  height: 150mm;
  background-color: #eceff1;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const OverviewTextWrapper = styled.View`
  margin-top: 3mm;
  width: 100%;
`;

const OverviewText = styled.Text`
  font-family: 'averta-black';
  font-size: 72;
  line-height: 1;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  color: #d5d9db;
`;

const Logo = styled.Image`
  width: 45mm;
  margin-top: 20mm;
`;

const Qr = styled.Image`
  position: absolute;
  width: 50mm;
  height: 50mm;
  right: 0;
`;

const Discount = styled.Text`
  position: absolute;
  bottom: 25mm;
  right: 15mm;
  font-family: 'averta-bold';
  color: #90a4ae;
`;

const Price = styled.Text`
  position: absolute;
  bottom: 15mm;
  right: 15mm;
  font-family: 'averta-bold';
`;

const OverviewPDF: FC<OverviewPDFProps> = ({
  images,
  optionLists,
  product,
  price,
  qrDataUrl,
  discount,
}) => {
  const filteredOptionLists = optionLists.filter((optionList) => {
    return !optionList.dependencies.some((dependency) => {
      return dependency.type === DEPENDENCY_TYPE_GROUP;
    });
  });

  const showDiscount = !!(discount && discount > 0);

  return (
    <Document>
      <Page size="A4">
        <Top>
          <Logo src="/configurator/apex.png" />
          <OverviewTextWrapper>
            <OverviewText>Jouw</OverviewText>
            <OverviewText>ontwerp</OverviewText>
          </OverviewTextWrapper>
          {qrDataUrl && <Qr src={qrDataUrl} />}
        </Top>
        {images.map((image, index) => (
          <View key={index} style={styles.imageWrapper}>
            <Image src={image} style={styles.image} />
          </View>
        ))}
        <View style={styles.content}>
          <ColTitle
            optionLists={optionLists}
            filteredOptionLists={filteredOptionLists}
          />
          <ColValue
            product={product}
            optionLists={optionLists}
            filteredOptionLists={filteredOptionLists}
          />
          <ColPrice
            product={product}
            optionLists={optionLists}
            filteredOptionLists={filteredOptionLists}
          />
        </View>
        {showDiscount && (
          <Discount>- {numeral(discount).format('$0,0.00')}</Discount>
        )}
        <Price>
          {numeral(price + (product?.price || 0)).format('$0,0.00')}
        </Price>
      </Page>
    </Document>
  );
};

export const getQRDataURL = async (path: string) => {
  if (!path) {
    return;
  }

  return await new Promise<string>((resolve) =>
    QRCode.toDataURL(path, (_, url) => resolve(url)),
  );
};

export const generatePDF = async (
  optionLists: OptionList[],
  product?: Product,
  path?: string,
  discount?: number,
) => {
  const imageValues = getOptionListImageValues(optionLists);
  const images = await Promise.all(initializeImages(imageValues, optionLists));
  const price = getTotalPrice(0, optionLists) - (discount || 0);
  const qrDataUrl = path && (await getQRDataURL(path));

  const blob = await pdf(
    <OverviewPDF
      images={images}
      optionLists={optionLists}
      price={price}
      product={product}
      qrDataUrl={qrDataUrl}
      discount={discount}
    />,
  ).toBlob();

  return blob;
};

export const downloadPDF = async (
  optionLists: OptionList[],
  product?: Product,
  path?: string,
) => {
  const blob = await generatePDF(
    getVisibleOptionLists(optionLists),
    product,
    path,
  );

  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', 'cc5311.pdf');
  link.click();
};

export default OverviewPDF;
