import React, { FC } from 'react';
import styled from 'styled-components';
import Bike from '../organisms/Bike';
import Configuration from '../organisms/Configuration';
import NavigationSteps from '../organisms/NavigationSteps';
import Footer from '../organisms/Footer';
import Logo from '../atoms/Logo';
import Header from '../organisms/Header';
import { rgba } from 'polished';

export const Container = styled.div`
  display: flex;
  position: fixed;
  height: calc(100vh - ${({ theme }) => theme.footer.height.medium});
  width: 100%;
  background: linear-gradient(#fbfbfb, #e2e7ea);

  @media (max-width: 1040px) {
    /* position: relative; */
    height: 300px;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      height: 100px;
      width: 100%;
      top: 100%;
      background: linear-gradient(#e2e7ea, ${rgba('#e2e7ea', 0)});
      pointer-events: none;
    }
  }
`;

export const Wrapper = styled.div`
  width: calc(
    100% - ${({ theme }) => theme.optionLists.width} -
      ${({ theme }) => theme.step.size}
  );
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1040px) {
    width: 100%;
  }
`;

export const BikeWrapper = styled.div`
  width: 60%;
  margin: 0 20%;
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: 1920px) {
    width: 70%;
    margin: 0 15%;
  }

  @media (max-width: 1040px) {
    max-width: 340px;
    margin: 0 auto;
  }
`;

const Configurator: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div>
        <Container>
          <Wrapper>
            <Header>
              <Logo />
            </Header>
            <BikeWrapper>
              <Bike />
            </BikeWrapper>
          </Wrapper>
          <NavigationSteps />
        </Container>
        <Configuration />
      </div>
      <Footer />
    </div>
  );
};

export default Configurator;
