import React, { FC } from 'react';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Link from '../atoms/Link';
import Button from '../atoms/Button';
import { H1 } from '../atoms/Heading';
import { Formik, Form, FormikHelpers } from 'formik';
import { Box } from '../molecules/Layout';

interface FormProps {
  title: string;
  backRoute?: string;
  onSubmit: (values: any, helpers: FormikHelpers<any>) => void;
  submitText?: string;
  initialValues: any;
  primarySubmitButton?: boolean;
}

const BackButton = styled(Button)`
  margin: 2rem 0;
`;

const SubmitButton = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: flex-end;
`;

const Intro: FC<FormProps> = ({
  title,
  onSubmit,
  backRoute,
  children,
  initialValues,
  submitText,
  primarySubmitButton,
}) => {
  return (
    <Grid>
      <Row>
        <Col md={4}>
          {backRoute && (
            <Link to={backRoute}>
              <BackButton>Terug</BackButton>
            </Link>
          )}
          <H1 style={{ marginTop: backRoute ? 0 : '6rem' }}>{title}</H1>
        </Col>
        <Col md={8}>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <SubmitButton>
                  <Button loading={isSubmitting} primary={primarySubmitButton}>
                    {submitText ? submitText : 'Opslaan'}
                  </Button>
                </SubmitButton>
                <Box>{children}</Box>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Grid>
  );
};

export default Intro;
