import styled from 'styled-components';
import { rgba } from 'polished';
import React, { FC } from 'react';

const Wrapper = styled.div`
  display: flex;
`;

const StyledInput = styled.input`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  color: ${({ theme }) => theme.colors.gray[700]};
  padding: 0 1rem;
  font-size: 0.9375rem;
  display: block;
  width: 100%;

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.gray[100]};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`;

const Title = styled.span`
  top: 0;
  right: 0;
  height: 3rem;
  line-height: 3rem;
  padding-left: 1rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.gray[700]};
  white-space: nowrap;
`;

const Input: FC<React.ButtonHTMLAttributes<HTMLInputElement>> = ({
  children,
  title,
  ...props
}) => {
  return (
    <Wrapper>
      <StyledInput id={props.name} {...props} title={title}>
        {children}
      </StyledInput>
      {title && <Title>{title}</Title>}
    </Wrapper>
  );
};

export default Input;
