import React, { FC } from 'react';
import { Option, WEBPART_COLOR_HEX } from '../../../store/configurator/types';
import CheckIcon from 'mdi-react/CheckIcon';
import Color from '../Color';
import styled from 'styled-components';

interface ColorOptionProps {
  onClick?: () => void;
  option: Option;
}

const Wrapper = styled(Color)`
  margin: 0 0.3125rem 0.625rem;

  &.selected:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0.375rem 0.625rem 0.375rem;
    border-color: transparent transparent
      ${({ theme }) => theme.colors.gray[50]} transparent;
    top: calc(100% + 3px);
  }
`;

const isHexColor = (hex: string) => {
  return /^#[0-9A-F]{6}$/i.test(hex);
};

const ImageOption: FC<ColorOptionProps> = ({ option, onClick }) => {
  let color =
    option.textValues.find((textValue) => {
      return textValue.name === WEBPART_COLOR_HEX;
    })?.text || '#000';

  if (!isHexColor(color)) {
    color = '#000';
  }

  return (
    <Wrapper selected={option.selected} color={color} onClick={onClick}>
      {option.selected && <CheckIcon color="#fff" size="1rem" />}
    </Wrapper>
  );
};

export default ImageOption;
