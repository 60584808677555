import { cloneDeep } from 'lodash';
import {
  getOptionListsWithDefaultValues,
  getSelectedOptionListsAsQueryParams,
} from '../../utils/optionList';
import {
  ConfiguratorActionTypes,
  ConfiguratorState,
  LoadConfigurationFailure,
  LoadConfigurationStarted,
  LoadConfigurationSucces,
  LOAD_CONFIGURATION_FAILURE,
  LOAD_CONFIGURATION_STARTED,
  LOAD_CONFIGURATION_SUCCESS,
  SelectOption,
  SELECT_OPTION,
  UpdateOption,
  UPDATE_OPTION,
  LoadConfigurationStockSucces,
  LOAD_CONFIGURATION_STOCK_SUCCESS,
} from './types';

const initialState: ConfiguratorState = {
  configurations: [],
};

const loadOptionListsStarted = (
  state: ConfiguratorState,
  action: LoadConfigurationStarted,
) => {
  const configurations = cloneDeep(state.configurations);
  const configuration = configurations.find(
    (configuration) => configuration.productId === action.payload.productId,
  );

  if (!configuration) {
    configurations.push({
      productId: action.payload.productId,
      optionLists: [],
      isFetching: true,
      didInvalidate: false,
    });
  } else {
    configuration.isFetching = true;
    configuration.didInvalidate = false;
  }

  return {
    ...state,
    configurations,
  };
};

const loadOptionListsSuccess = (
  state: ConfiguratorState,
  action: LoadConfigurationSucces,
) => {
  const configurations = cloneDeep(state.configurations);
  const configuration = configurations.find(
    (configuration) => configuration.productId === action.payload.productId,
  );

  if (!configuration) {
    configurations.push({
      productId: action.payload.productId,
      optionLists: getOptionListsWithDefaultValues(
        action.payload.optionLists,
        action.payload.order,
        false
      ),
      isFetching: false,
      didInvalidate: false,
    });
  } else {
    const optionLists = action.payload.optionLists.map((optionList) => {
      // In order to keep the selected options.
      const existingOptionList = configuration.optionLists.find((ol) => {
        return ol.optionListId === optionList.optionListId;
      });

      const selectedOptions =
        existingOptionList?.options.filter((option) => option.selected) ?? [];

      selectedOptions.forEach((selectedOption) => {
        const option = optionList.options.find(
          (option) => option.lineNr === selectedOption.lineNr,
        );

        if (option) {
          option.selected = selectedOption.selected;

          // In order to keep existing stock.
          optionList.options.forEach((option) => {
            const stock = existingOptionList?.options.find(
              (o) => o.lineNr === option.lineNr,
            )?.stock;

            option.stock = stock;
          });
        }
      });

      return optionList;
    });

    configuration.optionLists = getOptionListsWithDefaultValues(
      optionLists,
      action.payload.order,
      true
    );
    configuration.isFetching = false;
    configuration.didInvalidate = false;
  }

  return {
    ...state,
    configurations,
  };
};

const loadOptionListsFailure = (
  state: ConfiguratorState,
  action: LoadConfigurationFailure,
) => {
  const configurations = cloneDeep(state.configurations);
  const configuration = configurations.find(
    (configuration) => configuration.productId === action.payload.productId,
  );

  if (!configuration) {
    configurations.push({
      productId: action.payload.productId,
      optionLists: [],
      isFetching: false,
      didInvalidate: true,
    });
  } else {
    configuration.isFetching = false;
    configuration.didInvalidate = true;
  }

  return {
    ...state,
    configurations,
  };
};

const loadConfigurationStockSucces = (
  state: ConfiguratorState,
  action: LoadConfigurationStockSucces,
) => {
  const configurations = cloneDeep(state.configurations);
  const configuration = configurations.find(
    (configuration) => configuration.productId === action.payload.productId,
  );

  if (configuration) {
    configuration.optionLists.forEach((optionList) => {
      const stockOptions = action.payload.options.filter(
        (stockOption) => stockOption.optionListId === optionList.optionListId,
      );

      optionList.options = optionList.options.map((option) => {
        const stockOption = stockOptions.find(
          (stock) => stock.optionLineNr === option.lineNr,
        );

        if (stockOption) {
          const stock = action.payload.stocks.find(
            (stock) => stock.barcode === stockOption.code,
          );

          if (stock) {
            return {
              ...option,
              stock,
            };
          }
        }

        return option;
      });
    });
  }

  return {
    ...state,
    configurations,
  };
};

const selectOption = (state: ConfiguratorState, action: SelectOption) => {
  const configurations = cloneDeep(state.configurations);
  const configuration = configurations.find(
    (configuration) => configuration.productId === action.payload.productId,
  );

  const optionList = configuration?.optionLists.find(
    (ol) => ol.optionListId === action.payload.optionListId,
  );

  const options = optionList?.options;

  // Find the option that needs to be selected.
  const option = options?.find(
    (option) => option.lineNr === action.payload.optionLineNr,
  );

  const geenOption = options?.find((o) => o.name === 'Geen');

  if (configuration && option) {
    // Deselect all options if multiple is not supported.
    if (!action.payload.multiple) {
      options?.forEach((option) => (option.selected = false));
      option.selected = true;

      configuration.queryParam = getSelectedOptionListsAsQueryParams(
        configuration.optionLists,
      );

      return {
        ...state,
        configurations,
      };
    }

    const selectedOptions =
      options?.filter((o) => o.selected && o.lineNr !== geenOption?.lineNr) ??
      [];

    // If selected option is 'geenOption', deselect all other options

    if (option.lineNr === geenOption?.lineNr) {
      options?.forEach((o) => {
        if (o.lineNr !== option.lineNr) {
          o.selected = false;
        }
      });
      geenOption.selected = true;
    } else {
      option.selected = selectedOptions.length > 1 ? !option.selected : true;
      if (geenOption) {
        geenOption.selected = false;
      }
    }

    // Set selected options in query param.
    configuration.queryParam = getSelectedOptionListsAsQueryParams(
      configuration.optionLists,
    );

    return {
      ...state,
      configurations,
    };
  }

  return state;
};

const updateOption = (state: ConfiguratorState, action: UpdateOption) => {
  const configurations = cloneDeep(state.configurations);
  const configuration = configurations.find(
    (configuration) => configuration.productId === action.payload.productId,
  );

  const optionList = configuration?.optionLists.find(
    (ol) => ol.optionListId === action.payload.optionListId,
  );

  if (optionList) {
    const options = optionList.options;

    // Find the option that needs to be selected.
    const option = options.find(
      (option) => option.lineNr === action.payload.option.lineNr,
    );

    if (option) {
      Object.keys(option).forEach(
        (key) => (option[key] = action.payload.option[key]),
      );

      return {
        ...state,
        configurations,
      };
    }

    return state;
  }

  return state;
};

export const configuratorReducer = (
  state = initialState,
  action: ConfiguratorActionTypes,
): ConfiguratorState => {
  switch (action.type) {
    case LOAD_CONFIGURATION_STARTED:
      return loadOptionListsStarted(state, action);
    case LOAD_CONFIGURATION_SUCCESS:
      return loadOptionListsSuccess(state, action);
    case LOAD_CONFIGURATION_FAILURE:
      return loadOptionListsFailure(state, action);
    case LOAD_CONFIGURATION_STOCK_SUCCESS:
      return loadConfigurationStockSucces(state, action);
    case SELECT_OPTION:
      return selectOption(state, action);
    case UPDATE_OPTION:
      return updateOption(state, action);
    default:
      return state;
  }
};
