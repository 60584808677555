import React, { FC } from 'react';
import { Col, Text, Entry, Dependency } from './styles';
import {
  OptionList,
  OPTION_LIST_TYPE_COLOR,
} from '../../../store/configurator/types';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
// @ts-ignore
import styled from '@react-pdf/styled-components';
import { Product } from '../../../store/product/types';
import {
  getDependentOptionLists,
  getOptionListType,
} from '../../../utils/optionList';

numeral.locale('nl-nl');

interface ColValueProps {
  filteredOptionLists: OptionList[];
  optionLists: OptionList[];
  product?: Product;
}

const Value = styled(Text)`
  color: #455a64;
`;

const renderImageOptionLineDepencyNames = (
  optionList: OptionList,
  optionLists: OptionList[],
) => {
  const dependentOptionLists = getDependentOptionLists(
    optionLists,
    optionList.lineNr,
  );

  const options = dependentOptionLists.reduce((a: string[], c) => {
    const option = c.options.find((option) => option.selected);
    const type = getOptionListType(optionLists, c.optionListId);

    if (type === OPTION_LIST_TYPE_COLOR) {
      const color = getDependentOptionLists(optionLists, c.lineNr)[0];
      const option = color?.options.find((option) => option.selected);
      let name = "";
      if (option != null) {
        name = option?.name;
        const colorNumberText = option?.textValues?.find(tv => tv.name === 'color_number')?.text;
        if (colorNumberText != null) {
          name = `${name} (${colorNumberText})`;
        }
      }

      if (option) {
        return [...a, name];
      }
    }

    if (option) {
      return [...a, option.name];
    }

    return a;
  }, []);

  if (dependentOptionLists.length) {
    return <Dependency>{options.join(' • ')}</Dependency>;
  }

  return null;
};

const ColValue: FC<ColValueProps> = ({
  filteredOptionLists,
  optionLists,
  product,
}) => (
  <Col>
    <Entry>
      <Value>{product?.name}</Value>
    </Entry>
    {filteredOptionLists.map((optionList) => {
      const selectedOptions = optionList.options.filter(
        (option) => option.selected,
      );

      return selectedOptions.map((selectedOption) => (
        <Entry key={optionList.optionListId}>
          <Value>{selectedOption?.name}</Value>
          {renderImageOptionLineDepencyNames(optionList, optionLists)}
        </Entry>
      ));
    })}
  </Col>
);

export default ColValue;
