import React, { FC } from 'react';
import { OptionList } from '../../../store/configurator/types';
import styled from 'styled-components';
import DropdownOptionList from './DropdownOptionList';

interface ColorFinishOptionList {
  optionList: OptionList;
  authorized: boolean;
  productId?: number;
}

const Wrapper = styled.div`
  width: 100%;
`;

const ColorFinishOptionList: FC<ColorFinishOptionList> = ({
  optionList,
  authorized,
  productId,
}) => {
  return (
    <Wrapper>
      <DropdownOptionList
        productId={productId}
        optionList={optionList}
        authorized={authorized}
      />
    </Wrapper>
  );
};

export default ColorFinishOptionList;
