import React, { FC } from 'react';
import Box, { BOX_SIZE_SMALL } from '../molecules/Layout/Box';
import { BoxButtonButton } from '../atoms/Button';
import styled from 'styled-components';
import { Input, Checkbox, Label, Error, InputGroup } from '../molecules/Form';
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik';
import { sendToCustomer } from '../../store/configurator/actions';
import { generatePDF } from './OverviewPDF';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { Product } from '../../store/product/types';
import { Configuration } from '../../store/configurator/types';
import { getOptionLists, getVisibleOptionLists } from '../../utils/optionList';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

interface ShareModalProps {
  show: boolean;
  toggle: () => void;
  productId?: number;
}

const Wrapper = styled.div<{ show: boolean }>`
  position: absolute;
  bottom: calc(1rem + ${({ theme }) => theme.footer.height.medium});
  display: ${({ show }) => (show ? 'block' : 'none')};

  @media (max-width: 800px) {
    bottom: calc(1rem + ${({ theme }) => theme.footer.height.large});
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: 332px;
    padding: 0 16px;
  }
`;

const Button = styled(BoxButtonButton)`
  margin-bottom: 1rem;
`;

const Title = styled.h3`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0 0 1rem 0;
  line-height: 1.8125rem;
`;

const Privacy = styled.a`
  margin-top: 0.5rem;
  display: block;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.primary};
`;

// Formik
interface ShareModalValues {
  email: string;
  number: string;
  share: boolean;
}

const initialValues: ShareModalValues = {
  email: '',
  number: '',
  share: true,
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Het e-mailadres is verplicht')
    .email('Ongeldig e-mailadres'),
});

const ShareModal: FC<ShareModalProps> = ({ show, toggle, productId }) => {
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Redux.
  const dispatch = useDispatch();
  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const product = useSelector<RootState, Product | undefined>(({ product }) => {
    return product.products.find((product) => {
      return product.id === id;
    });
  });

  // ShareModal's methods.
  const onSubmit = async (
    values: ShareModalValues,
    helpers: FormikHelpers<ShareModalValues>,
  ) => {
    const pdf = await generatePDF(
      getVisibleOptionLists(optionLists),
      product,
      window.location.href,
    );

    await dispatch(
      sendToCustomer({
        configuration: pdf,
        configurationUrl: window.location.href,
        customerEmail: values.email,
        customerPhoneNumber: values.number,
        shareWithCC5311: values.share,
      }),
    );

    helpers.setSubmitting(false);
    toggle();
  };

  // Render.
  const { optionLists } = getOptionLists(configurations, id);

  return (
    <Wrapper show={show}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Title>
              Deel je <br /> ontwerp
            </Title>
            <Box size={BOX_SIZE_SMALL}>
              <InputGroup>
                <Label required htmlFor="email">
                  E-mail
                </Label>
                <Field
                  name="email"
                  placeholder="E-mail"
                  type="text"
                  as={Input}
                />
                <ErrorMessage name="email" component={Error} />
              </InputGroup>
              <InputGroup>
                <Label htmlFor="share">Deel je ontwerp met de winkel</Label>
                <Field name="share" type="checkbox" as={Checkbox} />
              </InputGroup>
              {values.share && (
                <>
                  <Label htmlFor="number">Telefoonnummer</Label>
                  <Field
                    name="number"
                    placeholder="Telefoonnummer"
                    type="text"
                    as={Input}
                  />
                </>
              )}
              <Privacy
                href="https://cdn.cookiecode.nl/privacy/apexbikes.cc/en/pdf"
                target="_blank"
              >
                Hiermee ga je akkoord <br /> met ons privacybeleid
              </Privacy>
            </Box>
            <Button loading={isSubmitting} disabled={isSubmitting || !isValid}>
              Ontwerp delen
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ShareModal;
