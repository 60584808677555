import {
  GlobalsState,
  GlobalsActionTypes,
  LOAD_GLOBALS_STARTED,
  LOAD_GLOBALS_SUCCESS,
  LOAD_GLOBALS_FAILURE,
  LoadGlobalsSuccess,
} from './types';
import { cloneDeep } from 'lodash';

const initialState: GlobalsState = {
  isFetching: false,
  didInvalidate: false,
  globals: null,
};

const loadGlobalsStarted = (state: GlobalsState) => {
  const newState = cloneDeep(state);

  newState.isFetching = true;
  newState.didInvalidate = false;

  return {
    ...state,
    ...newState,
  };
};

const loadGlobalsSuccess = (
  state: GlobalsState,
  action: LoadGlobalsSuccess,
) => {
  const newState = cloneDeep(state);

  newState.isFetching = false;
  newState.didInvalidate = false;
  newState.globals = action.payload.globals;

  return {
    ...state,
    ...newState,
  };
};

const loadGlobalsFailure = (state: GlobalsState) => {
  const newState = cloneDeep(state);

  newState.isFetching = false;
  newState.didInvalidate = true;

  return {
    ...state,
    ...newState,
  };
};

export const globalsReducer = (
  state = initialState,
  action: GlobalsActionTypes,
): GlobalsState => {
  switch (action.type) {
    case LOAD_GLOBALS_STARTED:
      return loadGlobalsStarted(state);
    case LOAD_GLOBALS_SUCCESS:
      return loadGlobalsSuccess(state, action);
    case LOAD_GLOBALS_FAILURE:
      return loadGlobalsFailure(state);
    default:
      return state;
  }
};
