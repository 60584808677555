import styled, { css } from 'styled-components';

interface RoundButtonProps {
  active?: boolean;
}

const RoundButton = styled.button<RoundButtonProps>`
  background-color: red;
  width: 2.5rem;
  height: 2.5rem;
  flex: 0 0 2.5rem;
  border-radius: 1.25rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[50]};
  cursor: pointer;

  .mdi-icon {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};

      .mdi-icon {
        color: #fff;
      }
    `}
`;

export default RoundButton;
