import React, { FC } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    primary: '#e70456',
    secondary: '#263238',
    white: '#fff',
    gray: {
      700: '#455A64',
      300: '#90A4AE',
      100: '#CFD8DC',
      50: '#ECEFF1',
      25: '#F5F7F8',
    },
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
  },
  space: {
    small: '8px',
    medium: '16px',
    large: '24px',
    configuration: {
      width: '400px',
    },
  },
  footer: {
    height: {
      medium: '5rem',
      large: '11rem',
    },
  },
  overview: {
    height: '30rem',
  },
  step: {
    size: '2rem',
  },
  optionLists: {
    width: '28rem',
    space: '48px',
    activeSpace: '184px',
  },
  flexboxgrid: {
    gutterWidth: 2,
  },
};

const GlobalFonts = createGlobalStyle`
  @font-face {
      font-family: 'averta';
      src: url('/fonts/averta-bold.woff2') format('woff2'),
          url('/fonts/averta-bold.woff') format('woff');
      font-weight: 700;
      font-style: normal;
  }

  @font-face {
      font-family: 'averta';
      src: url('/fonts/averta-regular.woff2') format('woff2'),
          url('/fonts/averta-regular.woff') format('woff');
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
      font-family: 'averta';
      src: url('/fonts/averta-black.woff2') format('woff2'),
          url('/fonts/averta-black.woff') format('woff');
      font-weight: 900;
      font-style: normal;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'averta';
    background-color: #e2e7ea;
    min-height: 100vh;
    height: 100%;
  }
  
  * {
    box-sizing: border-box;
  }
`;

const Theme: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    <GlobalFonts />
    <GlobalStyle />
  </ThemeProvider>
);

export default Theme;
