// @ts-ignore
import styled from '@react-pdf/styled-components';

export const Col = styled.View``;

export const Entry = styled.View`
  margin-bottom: 2mm;
`;

export const Text = styled.Text`
  font-family: 'averta-regular';
  font-size: 10;
  color: #90a4ae;
  margin-bottom: 1mm;
  margin-right: 25mm;
`;

export const Dependency = styled.Text`
  font-family: 'averta-regular';
  font-size: 10;
  color: #90a4ae;
`;
