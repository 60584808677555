import Jimp from 'jimp';
import { ImageValue, OptionList } from '../store/configurator/types';
import { getImageValueSrc } from './cdn';
import { getMaskColor } from './optionList';

export const recolorImage = async (path: string, color: string) => {
  return await Jimp.read(path).then((image) =>
    image
      .color([
        {
          apply: 'mix',
          params: [color, 100],
        },
      ])
      .getBase64Async(image.getMIME()),
  );
};

export const initializeImages = (
  values: ImageValue[],
  optionLists: OptionList[],
): Promise<string>[] => {
  const images = values.map((value) => {
    if (value.name.startsWith('image_mask_')) {
      const valueNumber = parseInt(value.name.substr(-1));
      const color = getMaskColor(optionLists, valueNumber - 1);
      const uri = getImageValueSrc(value.fileName, value.fileHash, 1600, 1200);

      return recolorImage(uri, color);
    }

    return Promise.resolve(
      getImageValueSrc(value.fileName, value.fileHash, 1600, 1200),
    );
  });

  return images;
};
