import styled from 'styled-components';

const Error = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 2rem;
  padding: 0 1rem;
  font-size: 0.75rem;
  border-radius: 0 0 0.25rem 0.25rem;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Error;
