import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ROOT,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response.status === 401 &&
      error.response.headers['token-expired']
    ) {
      const response = await refresh();

      if (response && response.status === 200) {
        return instance(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

const refresh = () => {
  return instance.post(`api/account/cookierefresh`, { rememberme: false });
};

export default instance;
