import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Configuration } from '../../../store/configurator/types';
import { getOptionLists, getTotalPrice } from '../../../utils/optionList';
import { Td, Tr } from './styles';
import { ProductState } from '../../../store/product/types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

interface TotalOptionLineProps {
  discount: number;
  exchangeBikeReturnAmount?: number;
  productId?: number;
}

const TotalTd = styled(Td)`
  color: ${({ theme }) => theme.colors.gray[700]};
  font-weight: bold;
`;

const TotalOptionLine: FC<TotalOptionLineProps> = ({ discount, exchangeBikeReturnAmount, productId }) => {
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Redux.
  const product = useSelector<RootState, ProductState>(
    ({ product }) => product,
  );

  const configurations = useSelector<RootState, Configuration[]>(
    ({ configurator }) => configurator.configurations,
  );

  const { loading, error, optionLists } = getOptionLists(configurations, id);

  // TotalOptionLine's methods:
  const getBasePrice = () => {
    if (loading || error) {
      return 0;
    }

    return product.products.find((product) => product.id === id)?.price || 0;
  };

  const price = getTotalPrice(getBasePrice(), optionLists);

  return (
    <Tr>
      <TotalTd />
      <TotalTd>Totaal</TotalTd>
      <TotalTd>{numeral(price - discount - (exchangeBikeReturnAmount ?? 0)).format('$0,0.00')}</TotalTd>
    </Tr>
  );
};

export default TotalOptionLine;
