import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Product } from '../../../store/product/types';
import { getImageValueSrc } from '../../../utils/cdn';
import numeral from 'numeral';
import 'numeral/locales/nl-nl';
import { useParams } from 'react-router-dom';

numeral.locale('nl-nl');

interface ProductOptionProps {
  onClick: () => void;
  product: Product;
  productId?: number;
}

const Wrapper = styled.div<{
  selected?: boolean;
}>`
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: ${({ theme }) => theme.borderRadius.small};

  ${({ selected }) =>
    !selected &&
    css`
      border-top: 1px solid #eceff1;

      &:first-child {
        border-top: 1px solid transparent;
      }

      &:hover {
        cursor: pointer;
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      border-top: 1px solid #eceff1;
      background-color: #eceff1;

      & + & {
        border-top-color: transparent !important;
      }
    `}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.9375rem;
  flex: 1;
  min-width: 0;
`;

const Title = styled.span`
  color: #455a64;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Price = styled.span`
  color: #90a4ae;
  margin-top: 0.125rem;
`;

const Thumbnail = styled.img`
  width: 4rem;
  height: 3rem;
  margin-right: 1rem;
`;

const ProductOption: FC<ProductOptionProps> = ({ product, productId, onClick }) => {
  // Render:
  const thumbnail = product.images.length ? product.images[0] : undefined;
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  return (
    <Wrapper selected={product.id === id} onClick={onClick}>
      {thumbnail && (
        <Thumbnail
          src={getImageValueSrc(
            thumbnail.fileName,
            thumbnail.fileHash,
            128,
            96,
          )}
        />
      )}
      <TextWrapper>
        <Title>{product.name}</Title>
        <Price>{`va. ${numeral(product.price || 0).format('$0,0.00')}`}</Price>
      </TextWrapper>
    </Wrapper>
  );
};

export default ProductOption;
