import React, { FC, useState } from 'react';
import { Option } from '../../../store/configurator/types';
import styled, { css } from 'styled-components';
import numeral from 'numeral';
import { getImageValueSrc } from '../../../utils/cdn';


interface DropdownOptionProps {
  onClick?: () => void;
  option: Option;
  authorized: boolean;
}

const LargeImage = styled.div`
  position: absolute;
  z-index: 2;
  right: 100%;
  top: 50%;
  transform: translateX(-1rem) translateY(-50%);
  width: clamp(15rem, 15vw, 30rem);
  height: clamp(15rem, 15vw, 30rem);
  background-color: ${({ theme }) => theme.colors.gray[700]};
  box-shadow: 0px 0px 1rem 0px rgba(0,0,0,.15);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;


const Wrapper = styled.li<{
  selected?: boolean;
}>`
  font-size: 0.9375rem;
  list-style-type: none;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.gray[700]};
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[50]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[50]};
  }

  span.price {
    font-size: 0.75rem;
  }

  ${({ selected }) =>
    selected &&
    css`
      span.name:after {
        content: '';
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 50%;
        margin-left: 0.5rem;
      }
    `}
`;

const DropdownOption: FC<DropdownOptionProps> = ({
  option,
  onClick,
  authorized,
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const code = option.textValues.find(
    (textValue) => textValue.name === 'color_number',
  )?.text;

  const imageValue = option.imageValues.find(
    (imageValue) => imageValue.name === 'photo_preview'
  );

  return (
    <>
      <Wrapper
        selected={option.selected}
        onClick={onClick}
        onMouseEnter={true ? () => setIsOpen(true) : undefined}
        onMouseLeave={true ? () => setIsOpen(false) : undefined}
      >
        <span className="name">
          {authorized && code ? `${option.name} (${code})` : option.name}
        </span>
        <span className="price">{`+ ${numeral(option.price).format(
          '$0,0.00',
        )}`}</span>
        {
          isOpen && !!imageValue &&
          <LargeImage style={{ backgroundImage: `url(${getImageValueSrc(imageValue.fileName, imageValue.fileHash, 700, 700)})` }} />
        }
      </Wrapper>
    </>
  );
};

export default DropdownOption;
