import {
  OrderActionTypes,
  OrderState,
  PlaceOrderSucces,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_RESET,
  PLACE_ORDER_STARTED,
  PLACE_ORDER_SUCCESS,
  SetExchangeBike,
  SET_EXCHANGE_BIKE,
  SET_DISCOUNT_CODE,
  SetDiscountCode,
  RESET_DISCOUNT_CODE,
  ExchangeBike,
} from './types';

const initialExchangeBike: ExchangeBike = {
  category: '',
  brand: '',
  type: '',
  color: '',
  frame: '',
  frameHeight: 0,
  frameNumber: '',
  modelYear: 20,
  price: 0,
  description: '',
  image: undefined,
  uploadedFile: undefined
};

const initialState: OrderState = {
  exchangeBike: initialExchangeBike,
  orderId: undefined,
  exchangeBikeActive: false,
  isOrderFetching: false,
  didOrderInvalidate: false,
  totalPriceDiscount: undefined
};

const setExchangeBike = (
  state: OrderState,
  action: SetExchangeBike,
): OrderState => {
  return {
    ...state,
    exchangeBike: action.payload.exchangeBike,
    exchangeBikeActive: true,
  };
};

const placeOrderStarted = (state: OrderState): OrderState => {
  return {
    ...state,
    orderId: undefined,
    isOrderFetching: true,
    didOrderInvalidate: false,
  };
};

const placeOrderSuccess = (
  state: OrderState,
  action: PlaceOrderSucces,
): OrderState => {
  return {
    ...state,
    orderId: action.payload.orderId,
    isOrderFetching: false,
    didOrderInvalidate: false,
  };
};

const placeOrderFailure = (state: OrderState): OrderState => {
  return {
    ...state,
    orderId: undefined,
    isOrderFetching: false,
    didOrderInvalidate: true,
  };
};

const placeOrderReset = (state: OrderState): OrderState => {
  return {
    ...state,
    ...initialState
  };
};

const setDiscountCode = (
  state: OrderState,
  action: SetDiscountCode,
): OrderState => {
  return {
    ...state,
    totalPriceDiscount: action.payload.discount,
  };
};

const resetDiscountCode = (state: OrderState): OrderState => {
  return {
    ...state,
    totalPriceDiscount: undefined,
  };
};

export const orderReducer = (
  state = initialState,
  action: OrderActionTypes,
): OrderState => {
  switch (action.type) {
    case SET_EXCHANGE_BIKE:
      return setExchangeBike(state, action);
    case PLACE_ORDER_STARTED:
      return placeOrderStarted(state);
    case PLACE_ORDER_SUCCESS:
      return placeOrderSuccess(state, action);
    case PLACE_ORDER_FAILURE:
      return placeOrderFailure(state);
    case PLACE_ORDER_RESET:
      return placeOrderReset(state);
    case SET_DISCOUNT_CODE:
      return setDiscountCode(state, action);
    case RESET_DISCOUNT_CODE:
      return resetDiscountCode(state);
    default:
      return state;
  }
};
