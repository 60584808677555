/* eslint-disable react-hooks/exhaustive-deps */
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { rgba } from 'polished';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { selectOption } from '../../../store/configurator/actions';
import { Option, OptionList } from '../../../store/configurator/types';
import { DropdownOption } from '../../atoms/Option';

interface DropdownOptionListProps {
  optionList: OptionList;
  authorized: boolean;
  productId?: number;
}

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 0.625rem;
  user-select: none;
`;

const Select = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  background-color: #fff;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  font-size: 0.9375rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.gray[700]};

  span {
    padding: 0 1rem;
    flex: 1;
  }
`;

const Dropdown = styled.div`
  height: 3rem;
  width: 3rem;
  flex: 0 0 3rem;
  background-color: ${({ theme }) => theme.colors.gray[50]};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.gray[700]};
  }
`;

const Options = styled.ul`
  position: absolute;
  z-index: 2;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 15px ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin: 0;
  padding: 0;
`;

const DropdownOptionList: FC<DropdownOptionListProps> = ({
  optionList,
  authorized,
  productId
}) => {
  const node = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const id = productId ?? parseInt((useParams() as { id: string }).id);

  // Redux.
  const dispatch = useDispatch();

  // DropdownOptionList's methods:
  const onSelectOption = (option: Option) => {
    if (id != null) {
      dispatch(selectOption(id, optionList.optionListId, option.lineNr, optionList.multiple));
    }
  };

  const handleClickOutside = (e: any) => {
    if (node.current?.contains(e.target)) {
      return;
    }

    setOpen(false);
  };

  const addMouseDownListener = () => {
    document.addEventListener('mousedown', handleClickOutside);
  };

  const removeMouseDownListener = () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };

  // DropdownOptionList's lifecycle:
  useEffect(() => {
    if (open) {
      addMouseDownListener();
    } else {
      removeMouseDownListener();
    }

    return removeMouseDownListener;
  }, [open]);

  const option = optionList.options.find((option) => option.selected);
  const code = option?.textValues.find(
    (textValue) => textValue.name === 'color_number',
  )?.text;

  return (
    <Wrapper ref={node}>
      <Select onClick={() => setOpen(!open)}>
        <span>
          {authorized && code ? `${option?.name} (${code})` : option?.name}
        </span>
        <Dropdown>
          <ChevronDownIcon />
        </Dropdown>
      </Select>
      <Options>
        {open &&
          optionList.options.map((option, index) => (
            <DropdownOption
              authorized={authorized}
              key={index}
              option={option}
              onClick={() => {
                onSelectOption(option);
                setOpen(false);
              }}
            />
          ))}
      </Options>
    </Wrapper>
  );
};

export default DropdownOptionList;
