import styled from 'styled-components';
import { rgba } from 'polished';

const Textarea = styled.textarea`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.gray[50]};
  color: ${({ theme }) => theme.colors.gray[700]};
  padding: 0.9375rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  display: block;
  width: 100%;
  resize: none;
  /* margin-bottom: 1.5rem; */

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.colors.gray[100]};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => rgba(theme.colors.primary, 0.2)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`;

export default Textarea;
