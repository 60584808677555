import React, { FC } from 'react';
import Theme from './Theme';
import Product from './components/pages/Product';
import Order from './components/pages/Order';
import User from './components/pages/User';
import Configure from './components/pages/Configure';
import Download from './components/pages/Download';
import Login from './components/pages/Login';
import Intro from './components/pages/Intro';
import ExchangeBike from './components/pages/ExchangeBike';
import EditOrder from './components/pages/EditOrder';
import LoadOrder from './components/templates/LoadOrder';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { rgba } from 'polished';

const ReactTooltipStyled = styled(ReactTooltip)`
  width: 272px;
  line-height: 0.9375rem;
  font-size: 0.75rem;
  opacity: 1 !important;
  box-shadow: 0 -10px 20px ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
`;

const App: FC = () => {
  return (
    <Theme>
      <ReactTooltipStyled
        effect="solid"
        place="left"
        backgroundColor="#fff"
        textColor="#90a4ae"
        html
        multiline
      />
      <Router>
        <Switch>
          <Route exact path="/" component={Product} />
          <Route exact path="/inloggen" component={Login} />
          <Route exact path="/:id" component={Intro} />
          <Route exact path="/:id/configureren" component={Configure} />
          <Route
            exact
            path="/:id/download"
            render={() => (
              <LoadOrder>
                {(productId) => <Download productId={productId} />}
              </LoadOrder>
            )}
          />
          <Route exact path="/:id/bestelling" component={Order} />
          <Route
            exact
            path="/:id/bestelling/inruilformulier"
            component={ExchangeBike}
          />
          <Route exact path="/:id/bestelling/gebruiker" component={User} />
          <Route exact path="/order/:id/aanpassen" component={EditOrder} />
        </Switch>
      </Router>
    </Theme>
  );
};

export default App;
