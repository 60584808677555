import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;

  > * {
    margin: 0 0.75rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default ButtonGroup;
